import BaseService from "./base.service";
import {API_URL} from "../config/constants";
import axios from "axios";

export default class PromotionService extends BaseService{
    constructor() {
        super();
        this.API_URL = API_URL + "pv/promotions/"
    }

    async getRepartitions(){
        return await
            axios
                .get(this.API_URL + "repartitions")
                .then(res => res.data)
                .catch(error => error)
    }

    async createPromotions(data){
        return await
            axios
                .post(this.API_URL + "create-promotions",data,{
                    headers:{
                        "Content-Type": "application/json"
                    }
                })
                .then(res => res.data)
                .catch(error => error)
    }

    async getData(){
        return await
            axios
                .get(this.API_URL + "get-data")
                .then(res => res.data)
                .catch(error => error)
    }

    async deletePromotion(id){
        return await
            axios
                .delete(this.API_URL + "delete-promotion/" + id)
                .then(res => res.data)
                .catch(error => error)
    }

    async savePromotion(data){
        return await
            axios
                .put(this.API_URL + "save-promotion/" + data.id, data, {
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                .then(res => res.data)
                .catch(error => error)
    }

}
