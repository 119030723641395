import { Fragment, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  searchResultActions,
  searchResultSelector,
} from "./app/slice/search-result.slice";
import ReservationService from "./service/reservation.service";
import moment from "moment";
import AppText from "./components/app-text";
import { clientStateSelector } from "./app/slice/client.slice";
import { getContenuByCle } from "./config/utils";
import { BiPlus } from "react-icons/bi";
const service = new ReservationService();
export default function SearchForm({ parent = "Landing" }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { langues } = useSelector(clientStateSelector);
  const { dateRange, roomsForms } = useSelector(searchResultSelector);

  const setRoomsForms = (value) => {
    dispatch(searchResultActions.setRoomsForms(value));
  };

  const addRoom = () => {
    if (roomsForms.length > 3) return;
    setRoomsForms([
      ...roomsForms,
      { id: roomsForms.length + 1, nb_adultes: 0, nb_enfants: 0 },
    ]);
  };

  const deleteRoom = (id) => {
    setRoomsForms(roomsForms.filter((rf) => rf.id !== id));
  };

  const updateRoom = (field, value, room_id) => {
    let roomsFormWU = JSON.parse(JSON.stringify(roomsForms));
    const index = roomsForms.findIndex((rf) => rf.id == room_id);
    roomsFormWU[index][field] = value;

    if (field === "nb_enfants") {
      [...Array(value)].forEach((_, ae) => {
        roomsFormWU[index][`age_enfant_${ae + 1}`] = 0;
      });
    }
    setRoomsForms(roomsFormWU);
  };
  const submit = (e) => {
    if (e) e.preventDefault();
    service
      .getHebergements({
        ...dateRange,
        nb_chambres: roomsForms.length,
        chambres: roomsForms,
      })
      .then((res) => {
        dispatch(searchResultActions.setIsLoading());
        dispatch(searchResultActions.setResult(res));
      });
  };
  const setDateRange = (value) => {
    dispatch(searchResultActions.setDate(value));
  };
  useEffect(() => {
    let repartition = {
      nb_chambres: 0,
      nb_adultes: 0,
      nb_enfants: 0,
      nb_bebes: 0,
    };
    roomsForms.forEach((room) => {
      repartition.nb_chambres++;
      repartition.nb_adultes += room.nb_adultes;
      if (room.nb_enfants > 0) {
        for (let i = 0; i < room.nb_enfants; i++) {
          if (room[`age_enfant_${i + 1}`] > 3) repartition.nb_enfants++;
          else repartition.nb_bebes++;
        }
      }
    });
    dispatch(searchResultActions.setRepartition(repartition));
  }, [roomsForms, dispatch]);
  const toggleSearchForm = () => {
    const searchForm = document.querySelector("#search-form");

    if (searchForm.classList.contains("show")) {
      searchForm.classList.remove("show");
    } else {
      searchForm.classList.add("show");
    }
  };
  return (
    <div>
      <div
        className="modal fade modal-lg"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="rooms-form modal-content">
            <div
              className={"d-flex justify-content-between align-items-center"}
              style={{
                borderBottom: "1px solid rgb(214,171,95)",
                paddingBottom: 4,
              }}
            >
              <h3 className="pt-1 ps-3">
                <AppText cle={"titre_popup_selection_chambres"} />
              </h3>
              <div data-bs-dismiss="modal" className={"app-button"}>
                x
              </div>
            </div>
            <a style={{ margin: 16 }} onClick={addRoom} href="#">
              <BiPlus />
              <AppText cle={"ajouter_chambre"} />
            </a>
            <div className="container">
              <div className={"row"}>
                {roomsForms.map((rf) => (
                  <div key={rf.id} className="room-row card col-4 col-md-3">
                    <div className={"d-flex card-header"}>
                      <span>
                        <AppText cle={"chambre"} /> {rf.id}{" "}
                      </span>
                      {rf.id === roomsForms.length && rf.id !== 1 && (
                        <span
                          style={{ marginLeft: 4 }}
                          onClick={() => deleteRoom(rf.id)}
                          className={"app-button-small"}
                        >
                          x
                        </span>
                      )}
                    </div>
                    <div className="form-group">
                      <small>
                        <AppText cle={"form_chambres_adultes"} />
                      </small>
                      <select
                        value={rf.nb_adultes}
                        onChange={(e) =>
                          updateRoom(
                            "nb_adultes",
                            Number(e.target.value),
                            rf.id
                          )
                        }
                        className={"form-select"}
                        name=""
                        id=""
                      >
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        {/* <option value="3">3</option>
                        <option value="4">4</option> */}
                      </select>
                    </div>
                    <div className="form-group">
                      <small>
                        <AppText cle={"form_chambres_enfants"} />
                      </small>
                      <select
                        value={rf.nb_enfants}
                        onChange={(e) =>
                          updateRoom(
                            "nb_enfants",
                            Number(e.target.value),
                            rf.id
                          )
                        }
                        className={"form-select"}
                        name=""
                        id=""
                      >
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                      </select>
                    </div>
                    {[...Array(rf.nb_enfants)].map((_, i) => (
                      <div className="form-group" key={i}>
                        <small>
                          <AppText cle={"Age enfant"} /> ({i + 1})
                        </small>
                        <select
                          onChange={(e) =>
                            updateRoom(
                              `age_enfant_${i + 1}`,
                              Number(e.target.value),
                              rf.id
                            )
                          }
                          className={"form-select"}
                          name=""
                          id=""
                        >
                          <option value="1">
                            <span>&#8804;</span>1
                          </option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                        </select>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>

            <button data-bs-dismiss="modal" className={"app-button"}>
              <AppText cle={"boutton_valider_reservation"} />
            </button>
          </div>
        </div>
      </div>
      <div id={parent === "Landing" ? "search-form" : ""}>
        <div className="container">
          <div id="search-form-close">
            <button
              style={{ padding: 8, float: "right", width: "fit-content" }}
              onClick={toggleSearchForm}
            >
              x
            </button>
            <br />
          </div>

          <div className="reservation">
            <form onSubmit={submit} className={"row"}>
              <div className="form-group col-12 col-md-3">
                <label htmlFor="">
                  <AppText cle={"Du"} />
                </label>
                <input
                  lang="fr-FR"
                  min={moment().format("YYYY-MM-DD")}
                  value={dateRange.du}
                  onChange={(e) =>
                    setDateRange({ ...dateRange, du: e.target.value })
                  }
                  type="date"
                  className="form-control"
                />
              </div>
              <div className="form-group col-12 col-md-3">
                <label htmlFor="">
                  <AppText cle={"au"} />
                </label>
                <input
                  min={moment(dateRange.du).add("days", 1).format("YYYY-MM-DD")}
                  value={dateRange.au}
                  onChange={(e) =>
                    setDateRange({ ...dateRange, au: e.target.value })
                  }
                  type="date"
                  className="form-control"
                />
              </div>
              <div className="col-12 col-md-4">
                <label htmlFor="">
                  <AppText cle={"Chambres"} />
                </label>
                <div
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  className={"input-group"}
                >
                  <input
                    placeholder={
                      localStorage.getItem("lng") === "ar"
                        ? `${getContenuByCle(
                            "form_chambres_enfants",
                            langues
                          )}(${roomsForms.reduce(
                            (a, b) => a + b.nb_enfants,
                            0
                          )}), ${getContenuByCle(
                            "form_chambres_adultes",
                            langues
                          )}(${roomsForms.reduce(
                            (a, b) => a + b.nb_adultes,
                            0
                          )}), ${getContenuByCle(
                            "form_chambres_chambres",
                            langues
                          )}(${roomsForms.length})
                    `
                        : `(${roomsForms.length}) ${getContenuByCle(
                            "form_chambres_chambres",
                            langues
                          )}(${roomsForms.reduce(
                            (a, b) => a + b.nb_adultes,
                            0
                          )})${getContenuByCle(
                            "form_chambres_adultes",
                            langues
                          )} (${roomsForms.reduce(
                            (a, b) => a + b.nb_enfants,
                            0
                          )}) ${getContenuByCle(
                            "form_chambres_enfants",
                            langues
                          )}
                    `
                    }
                    style={{ borderRight: 0 }}
                    type="text"
                    className="form-control"
                  />
                  <span className="input-group-text">
                    <i className={"bi bi-chevron-down"} />
                  </span>
                </div>
              </div>

              <div className={"col-12 col-md-2 d-flex text-align-end"}>
                {parent === "Landing" ? (
                  <button
                    onClick={() => {
                      submit();
                      navigate("/reservation");
                    }}
                    type={"button"}
                    className={"btn"}
                  >
                    <i className={"bi bi-search"} />{" "}
                    <AppText cle={"Recherche"} />
                  </button>
                ) : (
                  <button
                    type={"submit"}
                    className={"app-button mt-auto w-100"}
                    style={{ maxHeight: "unset", height: 45 }}
                  >
                    <i className={"bi bi-search"} />
                    <AppText cle={"Recherche"} />
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
