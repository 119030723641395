import {useEffect, useState} from "react";
import SelectMarche from "../../../components/select-marche";
import BaseService from "../../../service/base.service";

const service = new BaseService("ConditionChambre");
const FORM_IS = {
    id_marche: 0,
    ate_debut: "",
    date_fin: "",
    min_age_bebe: "",
    max_age_bebe: "",
    min_age_enfant: "",
    max_age_enfant: "",
    reduction_3eme_lit: "",
    reduction_4eme_lit: "",
    reduction_2ad_1er_enfant: "",
    reduction_2ad_2er_enfant: "",
    supplement_single_obligatoire: ""
}
export default function Conditions(){
    const [rows, setRows] = useState([]);
    const [form, setForm] = useState(FORM_IS);
    useEffect(() => {
        getAll()
    }, [])

    const onEditClicked = row => {
        setForm(row)
    }

    const postEntity = () => {
        service.create(form)
            .then(res => {
                if(res) {
                    resetForm()
                    getAll()
                }
            })
            .catch(error => console.log(error))
    }
    const putEntity = () => {
        service.save(form.id, form)
            .then(res => {
                if(res) {
                    resetForm()
                    getAll()
                }
            })
            .catch(error => console.log(error))
    }
    const deleteEntity = id => {
        service.delete(id)
            .then(res => {
                if(res) getAll()
            })
            .catch(error => console.log(error))
    }
    const submit = e => {
        if(!form.id) postEntity(); else putEntity()
    }

    const resetForm = () => {
        setForm(FORM_IS)
    }

    const getAll = () => {
        service
            .getAll()
            .then(res => setRows(res))
            .catch(error => console.log(error))

    }
    return (
        <div className={"row"}>
            <div className="col-12">
                <h3>Conditions chambres</h3>
            </div>
            <div className="col-12 col-md-3">
                <form action="" className={"row"}>
                    <div className="form-group col-12">
                        <SelectMarche/>
                    </div>
                    <div className="form-group col-12 col-md-6">
                        <label htmlFor="">date début</label><br/>
                        <input type="date" className={"form-control input-sm"}/>
                    </div>
                    <div className="form-group col-12 col-md-6">
                        <label htmlFor="">date fin</label><br/>
                        <input type="date" className={"form-control input-sm"}/>
                    </div>


                    <div className="form-group col-12 col-md-3">
                        <label htmlFor="">Min age bébé</label><br/>
                        <input type="number" step={1} className={"form-control input-sm"}/>
                    </div>
                    <div className="form-group col-12 col-md-3">
                        <label htmlFor="">Max age bébé</label><br/>
                        <input type="number" step={1} className={"form-control input-sm"}/>
                    </div>
                    <div className="form-group col-12 col-md-3">
                        <label htmlFor="">Min age enf.</label><br/>
                        <input type="number" step={1} className={"form-control input-sm"}/>
                    </div>
                    <div className="form-group col-12 col-md-3">
                        <label htmlFor="">Max age enf.</label><br/>
                        <input type="number" step={1} className={"form-control input-sm"}/>
                    </div>


                    <div className="form-group col-12 col-md-6">
                        <label htmlFor="">Réduction 3ème lit</label><br/>
                        <div className="input-group">
                            <input type="number" step={1} className={"form-control input-sm"}/>
                            <span className={"input-group-text"}>%</span>
                        </div>
                    </div>
                    <div className="form-group col-12 col-md-6">
                        <label htmlFor="">Réduction 4ème lit</label><br/>
                        <div className="input-group">
                            <input type="number" step={1} className={"form-control input-sm"}/>
                            <span className={"input-group-text"}>%</span>
                        </div>
                    </div>

                    <div className="row p-0">
                        <p className={"col-4"}>2Ad+Enf</p>
                        <div className="col-8 p-0">
                            <div className="form-group">
                                <div className="input-group">
                                    <span className={"input-group-text"}>Réd 1.enf</span>
                                    <input type="number" className="form-control input-sm"/>
                                    <span className={"input-group-text"}>%</span>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="input-group">
                                    <span className={"input-group-text"}>Réd 2.enf</span>
                                    <input type="number" className="form-control input-sm"/>
                                    <span className={"input-group-text"}>%</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="form-group col-12">
                        <label htmlFor="">Supp.single.obli</label><br/>
                        <input type="checkbox" className={"form-check-input input-sm"}/>
                    </div>
                    <div>
                        <button className={"btn btn-success btn-sm"} style={{float: "right"}}><i className={"bi-save"}/>Enregistrer</button>
                    </div>
                </form>
            </div>

            <div style={{borderLeft: "1px solid"}} className="col-12 col-md-9">
                <table className="table table-bordered table-sm">
                    <thead className={"table-dark"}>
                    <tr>
                        <th>#</th>
                        <th>Début</th>
                        <th>Fin</th>
                        <th>Réd 3e lit</th>
                        <th>Réd 4e lit</th>
                        <th>Réd 2Ad+enf</th>
                        <th>Réd 1Ad+enf</th>
                        <th>Réd 0Ad+enf</th>
                        <th>Supp single oblig</th>
                        <th>Age enfant</th>
                        <th>Age bébé</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        rows.map(r => (
                            <tr key={r.id}>
                                <td>{r.id}</td>
                                {/*<td>{r.}</td>*/}
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
            </div>
        </div>
    )
}
