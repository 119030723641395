import {configureStore} from "@reduxjs/toolkit"
import  searchResult from "./slice/search-result.slice";
import  reservations from "./slice/reservations-slice";
import  stopSale from "./slice/stopSale.slice";
import  client from "./slice/client.slice";

export const store = configureStore({
    reducer: {
        searchResult,
        reservations,
        stopSale,
        client
    }
})
