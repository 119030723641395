import { useSelector } from "react-redux";
import { clientStateSelector } from "../app/slice/client.slice";
import { useEffect, useState } from "react";

export default function AppText({ cle, sectionLabel = null }) {
  const { langues } = useSelector(clientStateSelector);
  const [text, setText] = useState(null);
  useEffect(() => {
    const lng = localStorage.getItem("lng");
    const foundText = sectionLabel !== null ? getContenuByCleAndSectionLabel(cle, sectionLabel) : getContenuByCle(cle);
    if (foundText) setText(foundText[lng]);
  }, [langues, cle]);

  // Function to find child object by its "cle" property
  const getContenuByCle = (cle) => {
    for (let i = 0; i < langues.length; i++) {
      const l = langues[i];
      for (let j = 0; j < l.contenus.length; j++) {
        const c = l.contenus[j];
        if (c.cle === cle) {
          return c;
        }
      }
    }
    return null;
  };

  const getContenuByCleAndSectionLabel = (cle, sectionLabel) => {
    if(!langues.find(l => l.label === sectionLabel)) return null
    return langues.find(l => l.label === sectionLabel).contenus.find(c => c.cle === cle)
  }
 
  if (!text) return <>...</>;
  return <>{text}</>;
}
