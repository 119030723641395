import { useEffect, useState } from "react";
import AppText from "../components/app-text";
import BaseService from "../service/base.service";
import { FaCalendarAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import SearchForm from "../search-form";
const service = new BaseService("Gastronomie");
export default function Gastro() {
  const [rows, setRows] = useState([]);
  const [lng, setLng] = useState(null);
  useEffect(() => {
    service.getAll().then((res) => setRows(res));
    setLng(localStorage.getItem('lng'))
  }, []);
  if(lng === null) return <AppText cle={"chargement"}/>
  return (
    <div>
      <div
        style={{
          height: "100vh",
          background: `url('assets/img/slide/slide-1.jpg')`,
          backgroundSize: "cover",
        }}
      >
        <div
          style={{
            height: "100%",
            background: `rgba(0,0,0,.6)`,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h2 className="container text-center" style={{ color: "var(--primary-color)" }}>
            <AppText cle={"titre"} sectionLabel={"page_gastro"}/>
          </h2>
          <h2 className="container text-center" style={{ color: "white" }}>
            <AppText cle={"sous_titre"} sectionLabel={"page_gastro"}/>
          </h2>
          <SearchForm/>
        </div>
      </div>
      <div className="container my-5">
        <h2 className="text-center my-5" style={{fontSize: "1.4rem"}}>
        <AppText cle={"description"} sectionLabel={"page_gastro"}/>
        </h2>
        <div className="row justify-content-center">
          {rows.map((r) => (
            <div key={r.id} className="col-12 col-md-6">
              <h3>{r.label}</h3>
              <img width={"100%"} height={350} src={r.image} alt="" />
              <div style={{ background: "var(--secondary-color)" }}>
                <div className="row">
                  <div className="col-12 col-md-6 p-3 text-center">
                    <div>
                      <strong style={{ color: "var(--primary-color)" }}>
                      <AppText cle={"Dates d'ouverture"} sectionLabel={"page_gastro"}/>
                      </strong>
                    </div>
                    <div style={{ color: "white" }}>{r[`dates_ouvertures_${lng}`]}</div>
                  </div>
                  <div className="col-12 col-md-6 p-3 text-center">
                    <div>
                      <strong style={{ color: "var(--primary-color)" }}>
                      <AppText cle={"Horaires d'ouvertures"} sectionLabel={"page_gastro"}/>
                      </strong>
                    </div>
                    <div style={{ color: "white" }}>{r[`heures_ouvertures_${lng}`]}</div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="my-5">
          <Link to={"/reservation"}>
            <button
              style={{
                marginLeft: "auto",
                background: "var(--primary-color)",
                color: "white",
                minWidth: 200,
              }}
              className="app-button p-3"
            >
              <FaCalendarAlt style={{ marginRight: 8 }} />
              <AppText cle={"reservez_en_ligne"}/>
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}
