import SearchForm from "./search-form";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  searchResultActions,
  searchResultSelector,
} from "./app/slice/search-result.slice";
import moment from "moment";
import ReservationService from "./service/reservation.service";
import AppModal from "./components/app-modal";
import AppText from "./components/app-text";
import { getContenuByCle } from "./config/utils";
import { clientStateSelector } from "./app/slice/client.slice";
const service = new ReservationService();
export default function SearchResult({ onBackClicked }) {
  const navigate = useNavigate();
  const [showForm, setShowForm] = useState(false);
  const [clientForm, setClientForm] = useState({
    nom: "",
    email: "",
    tel: "",
    adresse: "",
  });
  const [showModal, setShowModal] = useState(false);
  const searchResultState = useSelector(searchResultSelector);
  const { dateRange, repartition, result: rooms, detailsReservation } = searchResultState;
  const { langues } = useSelector(clientStateSelector);
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  // useEffect(() => {
  //     console.log("srs: ", rooms)
  // }, [searchResultState])

  const submitReservation = (e) => {
    e.preventDefault();
    const reservation = {
      ...repartition,
      ...clientForm,
      nb_nuits:detailsReservation.nb_nuits,
      date_reservation: moment().format("YYYY-MM-DD"),
      date_debut_sejour: moment(detailsReservation.du).format("YYYY-MM-DD"),
      date_fin_sejour: moment(detailsReservation.au).format("YYYY-MM-DD"),
      total: rooms
        .map((room, roomIndex) => {
          return { roomIndex, ...room.selectedHebergement };
        })
        .reduce(
          (a, b) => a + b.arrangements[rooms[b.roomIndex].selectedArrangement],
          0
        ),
    };
    const hebergements = rooms.map((room) => {
      const {
        categorie: hebergement,
        nb_adultes,
        nb_enfants,
        id,
      } = room.selectedHebergement;
      const nb_bebes = room.room.nb_bebes;
      const arrangement = room.selectedArrangement;
      const prix = room.selectedHebergement.arrangements[arrangement];
      return {
        hebergement,
        nb_adultes,
        nb_enfants,
        nb_bebes,
        arrangement,
        prix,
        id,
      };
    });
    reservation.hebergements = hebergements;
    reservation.lng = localStorage.getItem("lng");
    // return;
    service
      .save(reservation)
      .then((res) => {
        if (res.status === 500) {
          setMessage("Un erreur est survenu! veuillez nous contacter SVP!");
        } else setShowModal(true);
      })
      .catch((error) => console.log(error));
  };

  return (
    <main id={"main"}>
      <div id={"rooms-header"}>
        <Link to={"/"}>
          <h6 className={"home-link"}>
            <i className={"bi bi-arrow-left"} />
            <AppText cle={"acceuil_reservation"} />
          </h6>
        </Link>
        <div className="container-fluid" style={{ position: "absolute" }}>
          <h2 style={{ color: "white" }}>
            <AppText cle={"RÉSERVATION DES CHAMBRES"} />
          </h2>
        </div>
      </div>
      <div className={"mt-4"} style={{ marginLeft: 8, marginRight: 8 }}>
        <SearchForm parent={"SearchResult"} />
      </div>
      <div className="container">
        {searchResultState.loading ? (
          <p>
            <AppText cle={"chargement"} />
          </p>
        ) : (
          <div className="row">
            <div className="col-12 col-md-8">
              {searchResultState.result &&
              searchResultState.result.length > 0 ? (
                searchResultState.result.map((room, i) => (
                  <div className={"room-container"} key={i}>
                    <div className="room-container-title">
                      <div className="d-flex justify-content-between">
                        <h4>
                          Chambre {i + 1}
                          {[...Array(room.room.nb_adultes)].map(
                            (_, index_img) => (
                              <img
                                key={index_img}
                                src="assets/icons/adult.png"
                                alt=""
                              />
                            )
                          )}
                          {[...Array(room.room.nb_enfants)].map(
                            (_, index_img) => (
                              <img
                                key={index_img}
                                src="assets/icons/child.png"
                                alt=""
                              />
                            )
                          )}
                          {[...Array(room.room.nb_bebes)].map(
                            (_, index_img) => (
                              <img
                                key={index_img}
                                src="assets/icons/baby.png"
                                alt=""
                              />
                            )
                          )}
                        </h4>
                        <select
                          onChange={(e) =>
                            dispatch(
                              searchResultActions.setSelectedArrangement({
                                roomIndex: i,
                                arrangement: e.target.value,
                              })
                            )
                          }
                        >
                          {room.arrangements.map((a) => (
                            <option key={a} value={a}>
                              {a}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    {room.hebergements.length &&
                      room.hebergements.map((h, hi) => {
                        return (
                          <div key={hi} className="room row">
                            {/*image*/}
                            <div className="room-img col-12 col-md-3">
                              <i
                                onClick={() =>
                                  dispatch(
                                    searchResultActions.setSelectedHebergement({
                                      roomIndex: i,
                                      hebergement: h,
                                    })
                                  )
                                }
                                className={`bi ${
                                  room.selectedHebergement.categorie ===
                                  h.categorie
                                    ? "bi-circle-fill"
                                    : "bi-circle"
                                }`}
                              />
                              <img
                                src={h.images[0]?.image}
                                alt=""
                                width={"100%"}
                              />
                            </div>
                            {/*content*/}
                            <div className="room-content col-12 col-md-7">
                              {/*title*/}
                              <div className="room-title">
                                <h5>{h.categorie}</h5>
                              </div>
                              {/*specs*/}
                              <div className="room-specs">
                                {h.accessories?.map((ra) => (
                                  <div key={ra.id} className="d-flex ml-2">
                                    <img
                                      className="me-3"
                                      height={20}
                                      src={ra.image}
                                      alt="ra"
                                    />
                                    {/* <span>{ra.label}</span> */}
                                  </div>
                                ))}
                              </div>
                            </div>
                            {/*price*/}
                            <div className="room-reservation col-12 col-md-2">
                              {/*price*/}
                              <div className="room-price">
                                <h6>
                                  {h.arrangements[
                                    searchResultState.result[i]
                                      .selectedArrangement
                                  ].toFixed(3)}{" "}
                                  DT
                                </h6>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                ))
              ) : (
                <h3 className={"text-center mt-5"}>
                  <AppText cle={"Pas d'éhébrgements disponibles"} />
                </h3>
              )}
            </div>
            <div className="col-12 col-md-4">
              <div className="details-reservation">
                <div className="app-card">
                  <div className="app-card-title">
                    <span>
                      <AppText cle={"Votre Réservation"} />
                    </span>
                  </div>
                  <div className="app-card-divs">
                    <div className="app-card-div">
                      <i className={"bi bi-calendar"} />
                      <div>
                        <strong>
                          {detailsReservation.du !== ""
                            ? moment(detailsReservation.du).format("LL")
                            : getContenuByCle("Date du", langues)}
                        </strong>
                        <p>
                          {detailsReservation.du !== "" &&
                            moment(detailsReservation.du).format("dddd")}
                        </p>
                      </div>
                    </div>
                    <div className="app-card-div">
                      <i className={"bi bi-calendar"} />
                      <div>
                        <strong>
                          {detailsReservation.au !== ""
                            ? moment(detailsReservation.au).format("LL")
                            : getContenuByCle("Date au", langues)}
                        </strong>
                        <p>
                          {detailsReservation.au !== "" &&
                            moment(detailsReservation.au).format("dddd")}
                        </p>
                      </div>
                    </div>
                    <div className="app-card-div">
                      <i className={"bi bi-moon"} />
                      <div>
                        {detailsReservation.nb_nuits}
                        <p>
                          <AppText cle={"Nuité"} />
                          (s)
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="app-card">
                  <div className="app-card-title">
                    <span>
                      <AppText cle={"Répartitions"} />{" "}
                      <strong>({detailsReservation.nb_chambres})</strong>{" "}
                      <AppText cle={"form_chambres_chambres"} />
                    </span>
                  </div>
                  <div className="app-card-divs">
                    <div className="app-card-div">
                      <img src="assets/icons/adult.png" alt="" />
                      <div>
                        <strong>{detailsReservation.nb_adultes}</strong>
                        <p>
                          {" "}
                          <AppText cle={"form_chambres_adultes"} />
                        </p>
                      </div>
                    </div>
                    <div className="app-card-div">
                      <img src="assets/icons/child.png" alt="" height={20} />
                      <div>
                        <strong>{detailsReservation.nb_enfants}</strong>
                        <p>
                          {" "}
                          <AppText cle={"form_chambres_enfants"} />
                        </p>
                      </div>
                    </div>
                    <div className="app-card-div">
                      <img src="assets/icons/baby.png" alt="" />
                      <div>
                        <strong>{detailsReservation.nb_bebes}</strong>
                        <p>
                          {" "}
                          <AppText cle={"bebes"} />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="app-card">
                  <div className="app-card-title border-bottom-0">
                    {rooms && rooms.length > 0 ? (
                      <h3 style={{ textAlign: "center" }}>
                        <strong>
                          <AppText cle={"total"} />:{" "}
                        </strong>
                        {rooms &&
                          rooms
                            .map((room, roomIndex) => {
                              return { roomIndex, ...room.selectedHebergement };
                            })
                            .reduce(
                              (a, b) =>
                                a +
                                b.arrangements[
                                  rooms[b.roomIndex].selectedArrangement
                                ],
                              0
                            )
                            .toFixed(3)}{" "}
                        DT
                      </h3>
                    ) : (
                      <h3 className={"text-center"}>0 DT</h3>
                    )}
                  </div>
                  <div className="app-card-divs">
                    <button
                      disabled={searchResultState.result.length === 0 ||  !searchResultState.result.length}
                      onClick={() => {
                        setShowForm(true);
                      }}
                      className="app-button"
                      style={{
                        width: "100%",
                        paddingTop: 16,
                        paddingBottom: 16,
                      }}
                    >
                      <AppText cle={"Je réserve"} />
                    </button>
                  </div>
                </div>
                {/*form réservation*/}
                {showForm && (
                  <AppModal
                    content={
                      <div className="app-card">
                        <div className="app-card-title">
                          <h5>
                            <AppText cle={"Veuillez remplir ce formulaire"} />
                          </h5>
                        </div>
                        <form onSubmit={submitReservation}>
                          <div className="row mt-2">
                            <div className="col-12 col-md-6">
                              <div className="form-group">
                                <label htmlFor="name">
                                  <AppText cle={"Nom et prénom"} />
                                </label>
                                <input
                                  onChange={(e) =>
                                    setClientForm({
                                      ...clientForm,
                                      nom: e.target.value,
                                    })
                                  }
                                  value={clientForm.nom}
                                  type="text"
                                  placeholder={""}
                                  className={"form-control"}
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-12 col-md-6">
                              <div className="form-group">
                                <label htmlFor="name">
                                  <AppText cle={"Numéro téléphone"} />
                                </label>
                                <input
                                  onChange={(e) =>
                                    setClientForm({
                                      ...clientForm,
                                      tel: e.target.value,
                                    })
                                  }
                                  value={clientForm.tel}
                                  type="number"
                                  placeholder={""}
                                  className={"form-control"}
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="form-group">
                                <label htmlFor="name">
                                  <AppText cle={"email"} />
                                </label>
                                <input
                                  onChange={(e) =>
                                    setClientForm({
                                      ...clientForm,
                                      email: e.target.value,
                                    })
                                  }
                                  value={clientForm.email}
                                  type="email"
                                  placeholder={""}
                                  className={"form-control"}
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="form-group">
                                <label htmlFor="name">
                                  <AppText cle={"adresse"} />
                                </label>
                                <input
                                  onChange={(e) =>
                                    setClientForm({
                                      ...clientForm,
                                      adresse: e.target.value,
                                    })
                                  }
                                  value={clientForm.adresse}
                                  type="text"
                                  placeholder={""}
                                  className={"form-control"}
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-12">
                              <button
                                type={"submit"}
                                className={"app-button w-100 mt-2"}
                                style={{ paddingTop: 16, paddingBottom: 16 }}
                              >
                                <AppText cle={"Confirmer la réservation"} />
                              </button>
                            </div>
                          </div>
                          {message !== "" && (
                            <p style={{ color: "red" }}>{message}</p>
                          )}
                        </form>
                      </div>
                    }
                    onCloseClicked={() => setShowForm(false)}
                    modalTitle={""}
                  ></AppModal>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      {showModal && (
        <AppModal
          modalTitle={"Message de confirmation"}
          content={
            <div>
              <p>
                <AppText cle={"message_succes_res_1"} />
              </p>
              <p>
                <AppText cle={"message_succes_res_2"} />
              </p>
              <strong className={"mt-2"}>
                <AppText cle={"message_succes_res_3"} />
              </strong>
            </div>
          }
          onCloseClicked={() => window.location.reload()}
          // onCloseClicked={() => setShowModal(false)}
        />
      )}
    </main>
  );
}
