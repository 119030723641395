import axios from "axios";
import { UPLOAD_URL } from "../config/constants";
import axiosInstance from "../config/authRequest";

class UploadFilesService {
    upload(file, onUploadProgress) {
        let formData = new FormData();

        formData.append("file", file);

        return axiosInstance.post(UPLOAD_URL, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress,
        });
    }

}

export default new UploadFilesService();
