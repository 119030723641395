export const getContenuByCle = (cle, langues) => {
  const lng = localStorage.getItem('lng')
  for (let i = 0; i < langues.length; i++) {
      const l = langues[i];
      for (let j = 0; j < l.contenus.length; j++) {
        const c = l.contenus[j];
        if (c.cle === cle) {
          return c[lng];
        }
      }
    }
    return "...";
  };