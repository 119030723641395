import axios from "axios";
import { useState } from "react"
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import { API_URL } from "../../../config/constants";
import { useNavigate } from "react-router-dom";
export default function Login() {
    const [loginForm, setLoginForm] = useState({ login: "", password: "" });
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate()
    const handleChangeForm = e => {
        const { name, value } = e.target
        setLoginForm({ ...loginForm, [name]: value })
    }
    const submit = async e => {
        e.preventDefault()
        try {
            const response = await axios.post(API_URL + "auth/login", loginForm);
      
            // Save the JWT token to local storage or cookies
            localStorage.setItem('token', response.data.token);
            navigate('/admin')
          } catch (error) {
            console.error(error);
            alert(error.response.data.message)
          }
    }
    return (
        <div
            style={{
                height: "100vh",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <div className="card p-4">
                <img src="assets/img/logo-Royal-ASBU.png" width={250} alt="" />
                <form onSubmit={submit}>
                    <div className="form-group">
                        <label htmlFor="login">Login</label>
                        <input value={loginForm.login} onChange={handleChangeForm} type="text" className="form-control" id="login" name="login" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Mot de passe</label>
                        <div className="input-group mb-3">
                            <input value={loginForm.password} onChange={handleChangeForm} type={showPassword ? "text" : "password"} className="form-control" id="password" name="password" />
                            <button
                                onClick={() => setShowPassword(!showPassword)}
                                className="btn btn-outline-secondary" type="button" id="button-addon2">
                                {
                                    showPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />
                                }
                            </button>
                        </div>
                    </div>

                    <button className="app-button w-100" type="submit">Se connecter</button>
                </form>
            </div>
        </div>
    )
}