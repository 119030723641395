import AdminPageTitle from "../../components/admin-page-title";
import { BiPlus } from "react-icons/bi";
import { useEffect, useState } from "react";
import BaseService from "../../service/base.service";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import FileUploader from "../../components/file-uploader";

const service = new BaseService("Gastronomie");
const FORM_IS = {
  label: "",
  dates_ouvertures_ar: "",
  dates_ouvertures_en: "",
  dates_ouvertures_fr: "",
  heures_ouvertures_ar: "",
  heures_ouvertures_en: "",
  heures_ouvertures_fr: "",
  image: "",
};
export default function GestionGastronomie() {
  const [rows, setRows] = useState([]);
  const [form, setForm] = useState(FORM_IS);
  useEffect(() => {
    getAll();
  }, []);

  const onEditClicked = (row) => {
    setForm(row);
  };

  const postEntity = () => {
    service
      .create(form)
      .then((res) => {
        if (res) {
          resetForm();
          getAll();
        }
      })
      .catch((error) => console.log(error));
  };
  const putEntity = () => {
    service
      .save(form.id, form)
      .then((res) => {
        if (res) {
          resetForm();
          getAll();
        }
      })
      .catch((error) => console.log(error));
  };
  const deleteEntity = (id) => {
    service
      .delete(id)
      .then((res) => {
        if (res) getAll();
      })
      .catch((error) => console.log(error));
  };
  const submit = (e) => {
    if (!form.id) postEntity();
    else putEntity();
  };

  const resetForm = () => {
    setForm(FORM_IS);
  };
  const getAll = () => {
    service
      .getAll()
      .then((res) => setRows(res))
      .catch((error) => console.log(error));
  };
  return (
    <div>
      <AdminPageTitle title={"Gastronomie"} />
      <button
        data-bs-toggle="modal"
        data-bs-target="#form-modal"
        className={"btn btn-success mb-2"}
      >
        <BiPlus />
        Ajouter
      </button>

      <table className={"table table-striped"}>
        <thead className={"table-dark"}>
          <tr>
            <th>#</th>
            <th>Libellé</th>
            <th>Dates d'ouvertures</th>
            <th>Heures d'ouvertures</th>
            <th>Image</th>
            <th>Actions</th>
          </tr>
        </thead>

        <tbody>
          {rows.map((r, i) => (
            <tr key={r.id}>
              <td>{i + 1}</td>
              <td>{r.label}</td>
              <td>
                {r.dates_ouvertures_fr}<br/>
                {r.dates_ouvertures_ar}<br/>
                {r.dates_ouvertures_en}
              </td>
              <td>
                {r.heures_ouvertures_fr}<br/>
                {r.heures_ouvertures_ar}<br/>
                {r.heures_ouvertures_en}
              </td>
              <td>
                <img src={r.image} height={250} width={250} alt="is" />
              </td>
              <td>
                <button
                  onClick={() => deleteEntity(r.id)}
                  className={"btn btn-danger"}
                >
                  <AiFillDelete />
                  Supp.
                </button>
                <button
                  data-bs-toggle="modal"
                  data-bs-target="#form-modal"
                  onClick={() => {
                    onEditClicked(r);
                  }}
                  className={"btn btn-warning"}
                  style={{ marginLeft: 16 }}
                >
                  <AiFillEdit />
                  Modif.
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div
        className="modal fade"
        id="form-modal"
        tabIndex="-1"
        aria-labelledby="modal"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Formulaire Gastronomie
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={resetForm}
              />
            </div>
            <div className="modal-body row">
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="input-label">Label</label>
                  <input
                    id={"input-label"}
                    type="text"
                    className="form-control input-sm"
                    value={form.label}
                    onChange={(e) =>
                      setForm({ ...form, label: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-group">
                  <label htmlFor="input-ouvertures-fr">
                    Dates d'ouvertures fr
                  </label>
                  <input
                    id={"input-ouvertures-fr"}
                    type="text"
                    className="form-control input-sm"
                    value={form.dates_ouvertures_fr}
                    onChange={(e) =>
                      setForm({ ...form, dates_ouvertures_fr: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-group">
                  <label htmlFor="input-ouvertures-ar">
                    Dates d'ouvertures ar
                  </label>
                  <input
                    id={"input-ouvertures-ar"}
                    type="text"
                    className="form-control input-sm"
                    value={form.dates_ouvertures_ar}
                    onChange={(e) =>
                      setForm({ ...form, dates_ouvertures_ar: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-group">
                  <label htmlFor="input-ouvertures-en">
                    Dates d'ouvertures en
                  </label>
                  <input
                    id={"input-ouvertures-en"}
                    type="text"
                    className="form-control input-sm"
                    value={form.dates_ouvertures_en}
                    onChange={(e) =>
                      setForm({ ...form, dates_ouvertures_en: e.target.value })
                    }
                  />
                </div>
              </div>

              {/* heures d'ouveture */}
              <div className="col-12 col-md-6">
                <div className="form-group">
                  <label htmlFor="input-h-ouvertures-fr">
                    Heures d'ouvertures fr
                  </label>
                  <input
                    id={"input-h-ouvertures-fr"}
                    type="text"
                    className="form-control input-sm"
                    value={form.heures_ouvertures_fr}
                    onChange={(e) =>
                      setForm({ ...form, heures_ouvertures_fr: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-group">
                  <label htmlFor="input-h-ouvertures-ar">
                  Heures d'ouvertures ar
                  </label>
                  <input
                    id={"input-h-ouvertures-ar"}
                    type="text"
                    className="form-control input-sm"
                    value={form.heures_ouvertures_ar}
                    onChange={(e) =>
                      setForm({ ...form, heures_ouvertures_ar: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-group">
                  <label htmlFor="input-h-ouvertures-en">
                  Heures d'ouvertures en
                  </label>
                  <input
                    id={"input-h-ouvertures-en"}
                    type="text"
                    className="form-control input-sm"
                    value={form.heures_ouvertures_en}
                    onChange={(e) =>
                      setForm({ ...form, heures_ouvertures_en: e.target.value })
                    }
                  />
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="image-uploader">Image</label>
                <FileUploader
                  id={"image-uploader"}
                  onUrlReceived={(url) => setForm({ ...form, image: url })}
                  html={
                    <label
                      style={{
                        height: 350,
                        width: "100%",
                        border: "1px solid lightgray",
                      }}
                      className={
                        "d-flex justify-content-center align-items-center"
                      }
                      htmlFor={"image-uploader"}
                    >
                      {form.image !== "" ? (
                        <img
                          src={form.image}
                          height={"100%"}
                          width={"100%"}
                          alt=""
                        />
                      ) : (
                        <span>+</span>
                      )}
                    </label>
                  }
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                onClick={resetForm}
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Fermer
              </button>
              <button
                onClick={submit}
                type="button"
                data-bs-dismiss="modal"
                className="btn btn-primary"
              >
                Enregistrer
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
