import {useEffect, useState} from "react";
import PromotionService from "../../../../service/promotion.service";
import moment from "moment";
const service = new PromotionService()
const arrangements = ["lpd", "dp", "dp_plus", "pc", "pc_plus", "ai_soft", "ai", "ultra_ai"]

export default function List(){
    const [rows, setRows] = useState([]);
    useEffect(() => {
        getData()
    }, [])

    const getData = () => {
        service
            .getData()
            .then(res => setRows(res))
    }

    const deletePromotion = id => {
        service
            .deletePromotion(id)
            .then(res  => {
                getData()
            })
    }

    const updatePromotion = (id_promotion, field, value) => {
        let rowsWU = [...rows]
        const index = rows.findIndex(promotion => promotion.id === id_promotion)
        rowsWU[index][field] = value
        setRows(rowsWU)
    }

    const submit = promotion => {
        service
            .savePromotion(promotion)
            .then(res => {
                getData()
            })
    }
    return (
        <div>
            <h3>Liste promos</h3>
            <div className="table-responsive">
                <table className={"table-prix-chambre table table-bordered table-striped table-hover"}>
                    <thead className={"table-dark"}>
                    <tr>
                        <th>du_res</th>
                        <th>au_res</th>
                        <th>du_app</th>
                        <th>au_app</th>
                        <th>Chambre</th>
                        <th>Promo</th>
                        <th>ad</th>
                        <th>enf</th>
                        <th>LPD</th>
                        <th>DP</th>
                        <th>DP_PLUS</th>
                        <th>PC</th>
                        <th>PC_PLUS</th>
                        <th>AI_SOFT</th>
                        <th>AI</th>
                        <th>ULTRAS_AI</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        rows.map(promotion => {
                            const {repartition} = promotion
                            const {room} = repartition
                            return (
                                <tr key={promotion.id}>
                                    <td>{promotion.date_reservation_du && moment(promotion.date_reservation_du).format("DD/MM/YYYY")}</td>
                                    <td>{promotion.date_reservation_au && moment(promotion.date_reservation_au).format("DD/MM/YYYY")}</td>
                                    <td>{promotion.date_application_du && moment(promotion.date_application_du).format("DD/MM/YYYY")}</td>
                                    <td>{promotion.date_application_au && moment(promotion.date_application_au).format("DD/MM/YYYY")}</td>
                                    <td>{room.categorie}</td>
                                    <td><span className={"text-success"}>{promotion.label}</span></td>
                                    <td>{repartition.nb_adulte}</td>
                                    <td>{repartition.nb_enfant}</td>
                                    {
                                        arrangements.map(arrangement => (
                                            <td key={arrangement}><input onChange={e => updatePromotion(promotion.id, arrangement, e.target.value)} type={"number"} value={promotion[arrangement] || 0}/></td>
                                        ))
                                    }
                                    <td>
                                        <button style={{fontSize: 12, width: 100}} onClick={() => submit(promotion)} className={"text-primary mx-auto"}><i className={"bi bi-pencil"}/>modifier</button>
                                        <button style={{fontSize: 12, width: 100}} onClick={() => deletePromotion(promotion.id)} className={"text-danger mx-auto"}><i className={"bi bi-trash"}/>supprimer</button>
                                    </td>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </table>
            </div>
        </div>
    )
}
