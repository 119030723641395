export const API_URL =
  process.env.NODE_ENV === "production"
    ? "https://api.kreatek.tn/asbu/api/"
    : "http://localhost:3001/api/";
export const UPLOAD_URL =
  process.env.NODE_ENV === "production"
    ? "https://api.kreatek.tn/asbu/upload/"
    : "http://localhost:3001/upload/";

export const conferenceI18 = {
  paperBoard: {
    en: "Paper board",
    fr: "Paper board",
    ar: "لوح ورقي",
  },
  ecranProjection: {
    en: "Screen projection",
    fr: "Écran de projection",
    ar: "عرض الشاشة",
  },
  videoProjecteur: {
    en: "Video projector",
    fr: "Vidéo projecteur",
    ar: "جهاز العرض الفيديو",
  },
  sonorisation: {
    en: "Sound with microphone + sound control",
    fr: "Sonorisation avec micro + régie son",
    ar: "صوت مع ميكروفون + تحكم صوتي",
  },
  traductionSimultanee: {
    en: "Simultaneous translation",
    fr: "Traduction simultanée",
    ar: "ترجمة فورية",
  },
  pausesCafe: {
    en: "Coffee breaks",
    fr: "Pauses café",
    ar: "استراحات قهوة",
  },
  cocktail: {
    en: "Cocktail",
    fr: "Cocktail",
    ar: "كوكتيل",
  },
  repasBuffet: {
    en: "Buffet-style meal or plated",
    fr: "Repas sous forme de buffet ou servi sur assiette",
    ar: "وجبة بوفيه أو مقدمة على صينية",
  },
  enGratuite: {
    en: "Free of charge",
    fr: "En gratuité",
    ar: "مجانًا",
  },
  blocNotes: {
    en: "Notepads",
    fr: "Bloc-notes",
    ar: "دفاتر ملاحظات",
  },
  stylos: {
    en: "Pens",
    fr: "Stylos",
    ar: "أقلام",
  },
};

export const spaI18 = {
  downloadPrices: {
    fr: "Télécharger notre Brochure SPA",
    en: "Download our SPA Brochure",
    ar: "قم بتنزيل كتيب منتجات السبا لدينا",
  },
};
