import { useState } from "react";
import AppText from "./components/app-text";
import { getContenuByCle } from "./config/utils";
import { useSelector } from "react-redux";
import { clientStateSelector } from "./app/slice/client.slice";

export default function Contact({ data, service, onLoad }) {
  const [showMessage, setShowMessage] = useState(false);
  const {langues} = useSelector(clientStateSelector)
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    name: "",
    subject: "",
    email: "",
    message: "",
  });
  const submit = (e) => {
    setLoading(true);
    e.preventDefault();
    service.postContact(form).then((_) => {
      setShowMessage(true);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    });
  };
return (
  
  <section onLoad={onLoad} id="contact" className="contact">
    <div className="container">
      <div className="section-title">
        <h2>
          <AppText cle={"contact"}/>
        </h2>
        <p>
          <AppText cle={"contact_st"}/>
        </p>
      </div>
    </div>

    <div className="map">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3193.033928273874!2d10.197132051324491!3d36.84166347984155!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12fd35394cff3415%3A0xfa1839498b42c834!2sROYAL%20ASBU%20TUNIS!5e0!3m2!1sen!2stn!4v1676834226033!5m2!1sen!2stn"
        width="600"
        height="450"
        style={{ border: 0, width: "100%", height: 350 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      />
    </div>

    <div className="container mt-5">
      <div className="info-wrap">
        <div className="row">
          <div className="col-lg-4 col-md-6 info">
            <i className="bi bi-geo-alt"></i>
            <h4><AppText cle={"localisation"}/>:</h4>
            <p>
              Centre urbain nord
              <br />
              Tunis
            </p>
          </div>

          <div className="col-lg-4 col-md-6 info mt-4 mt-lg-0">
            <i className="bi bi-envelope"></i>
            <h4><AppText cle={"email"}/>:</h4>
            <p>
              {data.email1}
              <br />
              {data.email2}
            </p>
          </div>

          <div className="col-lg-4 col-md-6 info mt-4 mt-lg-0">
            <i className="bi bi-phone"></i>
            <h4><AppText cle={"contact"}/>:</h4>
            <p>
              {data.tel1}
              <br />
              {data.tel2}
            </p>
          </div>
        </div>
      </div>

      <form onSubmit={submit} className="php-email-form">
        <div className="row">
          <div className="col-md-6 form-group">
            <input
              type="text"
              name="name"
              className="form-control"
              id="name"
              placeholder={getContenuByCle('nom_prenom', langues)}
              required
              value={form.name}
              onChange={(e) => setForm({ ...form, name: e.target.value })}
            />
          </div>
          <div className="col-md-6 form-group mt-3 mt-md-0">
            <input
              type="email"
              className="form-control"
              name="email"
              id="email"
              placeholder={getContenuByCle('email', langues)}
              required
              value={form.email}
              onChange={(e) => setForm({ ...form, email: e.target.value })}
            />
          </div>
        </div>
        <div className="form-group mt-3">
          <input
            type="text"
            className="form-control"
            name="subject"
            id="subject"
            placeholder={getContenuByCle('sujet', langues)}
            required
            value={form.subject}
            onChange={(e) => setForm({ ...form, subject: e.target.value })}
          />
        </div>
        <div className="form-group mt-3">
          <textarea
            className="form-control"
            name="message"
            rows="5"
            placeholder={getContenuByCle('message', langues)}
            required
            value={form.message}
            onChange={(e) => setForm({ ...form, message: e.target.value })}
          />
        </div>
        <div className="text-center">
          <button disabled={loading} type="submit">
          <AppText cle={"boutton_envoyer"}/>
          </button>
          {showMessage && (
            <p className="text-success">
              <AppText cle={"message_succes"}/>
            </p>
          )}
        </div>
      </form>
    </div>
  </section>
)
}
