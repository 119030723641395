import { useState } from "react";
import FileUploader from "../../../components/file-uploader";
import { BsCloudUploadFill, BsFillSave2Fill } from "react-icons/bs";
import { CgSpinner } from "react-icons/cg";
import { BiEdit, BiTrash } from "react-icons/bi";
import BaseService from "../../../service/base.service";
import { useEffect } from "react";
import LoadingComponent from "../../../components/loading-component";
const FORM_IS = {
  label: "",
  src: "",
  type: "image",
};
const service = new BaseService("MediaSpa");
export default function MediaSpas() {
  const [form, setForm] = useState(FORM_IS);
  const [fileLoading, setFileLoading] = useState(false);
  const [rows, setRows] = useState(null);
  const [formLoading, setFormLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const handleChangeForm = (e) => {
    const { name, value } = e.target ?? e;
    const newForm = { ...form, [name]: value };
    setForm(newForm);
  };

  useEffect(() => {
    getAll();
  }, []);

  const getAll = () => {
    service
      .getAll()
      .then((res) => setRows(res))
      .catch((error) => {
        console.error(error);
        setError(error);
      });
  };

  const submitMedia = (e) => {
    setFormLoading(true);
    e.preventDefault();
    const submitAction = form.id
      ? service.save(form.id, form)
      : service.create(form);
    submitAction
      .then((res) => {
        if (!form.id) setForm({ ...form, id: res.id });
        getAll();
      })
      .catch((error) => {
        console.error(error);
        setError(error);
      })
      .finally(() => {
        setFormLoading(false);
        alert("Opération finie");
      });
  };

  const deleteMedia = (id) => {
    setLoading(true);
    service
      .delete(id)
      .then((_) => getAll())
      .catch((error) => {
        console.error(error);
        setError(error);
      })
      .finally(() => setLoading(false));
  };

  const onUpdateMediaClicked = (media) => {
    setForm(media);
  };

  const handleChangeOrder = (id, ordre) => {
    service
      .save(id, { ordre })
      .then((res) => {
        getAll();
      })
      .catch((error) => {
        setError(error);
        console.error(error);
      });
  };

  if (error)
    return (
      <>
        {JSON.stringify(error)}{" "}
        <button onClick={() => window.location.reload()}>Actualiser</button>
      </>
    );
  return (
    <div className="container">
      <h3>Gestion du média Spas</h3>

      <div className="row">
        <div className="col-12 col-md-4">
          <h3 className="border-bottom table-dark bg-dark py-2 text-center">
            Formulaire Média
          </h3>
          <form onSubmit={submitMedia} className="mb-3">
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="form-group">
                  <label htmlFor="label">Label</label>
                  <input
                    placeholder={form.type === "pdf" ? "brochure_fr/en/ar" : ""}
                    value={form.label}
                    name="label"
                    onChange={handleChangeForm}
                    type="text"
                    className="form-control"
                    id="label"
                  />
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group">
                  <label htmlFor="type">Type</label>
                  <select
                    onChange={handleChangeForm}
                    name="type"
                    id="type"
                    className="form-select"
                    value={form.type}
                  >
                    <option value="">-- Veuillez séléctionner --</option>
                    <option value="image">-Image-</option>
                    <option value="video">-Vidéo-</option>
                    <option value="pdf">-pdf-</option>
                  </select>
                </div>
              </div>
            </div>

            <FileUploader
              id={"spa-media-file-uploader"}
              onUrlReceived={(url) =>
                handleChangeForm({ name: "src", value: url })
              }
              setLoading={setFileLoading}
              html={
                <label
                  id="file-uploader-holder"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "1px solid #ddd",
                    cursor: "pointer",
                    borderRadius: 3,
                    marginTop: 12,
                    width: "100%",
                    height: 300,
                  }}
                  htmlFor="spa-media-file-uploader"
                >
                  {fileLoading ? (
                    <>Chargement...</>
                  ) : form.src ? (
                    form.type === "image" ? (
                      <img
                        alt="media spa"
                        src={form.src}
                        width={"100%"}
                        height={"100%"}
                      />
                    ) : form.type === "pdf" ? (
                      <div>
                        <div>
                          <button
                            type="button"
                            onClick={() => setForm({ ...form, src: "" })}
                          >
                            x
                          </button>
                        </div>
                        <iframe
                          title="pdf"
                          src={form.src}
                          width="100%"
                          height="300px"
                        ></iframe>
                      </div>
                    ) : (
                      <video controls width={"100%"}>
                        <source src={form.src} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    )
                  ) : (
                    <BsCloudUploadFill />
                  )}
                </label>
              }
            />
            {form.id && (
              <button onClick={() => setForm(FORM_IS)} className="me-2">
                {" "}
                + Nouveau
              </button>
            )}
            <button disabled={formLoading} className="mt-2">
              {formLoading ? (
                <>
                  <CgSpinner />
                  Chargement...
                </>
              ) : (
                <>
                  <BsFillSave2Fill />
                  Enrregistrer
                </>
              )}
            </button>
          </form>
        </div>

        <div className="col-12 col-md-8">
          <h3 className="border-bottom table-dark bg-dark py-2 text-center">
            Liste des Média
          </h3>
          {!rows ? (
            <>Chargement...</>
          ) : rows.length === 0 ? (
            <>Pas de données</>
          ) : (
            <div className="row">
              {rows
                .sort((a, b) => a.ordre - b.ordre)
                .map((r) => (
                  <div
                    className="col-12 col-md-3 pb-3 mb-3"
                    style={{ border: "2px solid" }}
                    key={r.id}
                  >
                    <h2 className="mt-2">{r.label}</h2>
                    <button onClick={() => onUpdateMediaClicked(r)}>
                      <BiEdit />
                    </button>
                    <button onClick={() => deleteMedia(r.id)} className="ms-2">
                      <BiTrash />
                    </button>
                    <label className="ms-4" htmlFor="ordre">
                      Ordre:{" "}
                    </label>
                    <input
                      value={r.ordre}
                      onChange={(e) => handleChangeOrder(r.id, e.target.value)}
                      id="ordre"
                      type="number"
                      step={1}
                      style={{ width: 40 }}
                    />
                    <div className="mt-2">
                      {r.type === "image" ? (
                        <img
                          src={r.src}
                          width={"100%"}
                          height={150}
                          alt={r.label}
                        />
                      ) : r.type === "pdf" ? (
                      
                          <iframe
                            title="pdf"
                            src={r.src}
                            width="100%"
                            height="150px"
                          ></iframe>
                        
                      ) : (
                        <video controls width={"100%"} height={150}>
                          <source src={r.src} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      )}
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>

      {loading && <LoadingComponent />}
    </div>
  );
}
