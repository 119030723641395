import { useState } from "react";
import AdminPageTitle from "../../../components/admin-page-title";
import BaseService from "../../../service/base.service";
import axios from "axios";
import { API_URL } from "../../../config/constants";
import { useEffect } from "react";
import axiosInstance from "../../../config/authRequest";
const FORM_IS = { login: "", password: "" }
const service = new BaseService('User')
export default function GestionUsers() {
    const [users, setUsers] = useState(null);
    const [form, setForm] = useState(FORM_IS);

    useEffect(() => {
        getAll()
    }, [])


    const resetForm = () => {
        setForm(FORM_IS)
    }
    const getAll = () => {
        service.getAll()
            .then(rows => setUsers(rows))
    }
    const handleChangeForm = e => {
        const { name, value } = e.target
        setForm({ ...form, [name]: value })
    }
    const submit = e => {
        setUsers(null)
        e.preventDefault()
        if (form.id)
            axiosInstance.put(
                API_URL + "auth/update-password",
                form
            )
                .then(res => {
                    console.log(res);
                    alert(res.data.message)
                })
                .catch(error => {
                    console.log(error)
                    alert(error.response.data.message)
                })
                .finally(() => {
                    getAll()
                    resetForm()
                })
        else
            axiosInstance.post(
                API_URL + "auth/register",
                form
            )
                .then(res => {
                    console.log(res);
                    alert(res.data.message)
                })
                .catch(error => {
                    console.log(error)
                    alert(error.response.data.message)
                })
                .finally(() => {
                    getAll()
                    resetForm()
                })
    }

    const deleteUser = id => {
        setUsers(null)
        service
            .delete(id)
            .then(_ => {
                alert('utilisateur supprimé avec succès')
            })
            .catch(error => {
                console.log(error);
                alert(error.response.data.message)
            })
            .finally(() => getAll())
    }
    if (!users) return <>chargement...</>
    return (
        <>
            <AdminPageTitle title={"Gestion des utilisateurs"} />

            <form onSubmit={submit} className="mb-3">
                <input required value={form.login} onChange={handleChangeForm} type="text" placeholder="login" name="login" />
                <input required value={form.password} onChange={handleChangeForm} type="text" placeholder="mdp" name="password" />
                <button>
                    {
                        form.id ? "Modifier" : "Ajouter"
                    }
                </button>
            </form>

            <table className="table table-sm table-hover table-striped">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Login</th>
                        <th>Mot de passe</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        users.filter(u => u.login !== "admin").map(u => (
                            <tr key={u.id}>
                                <td>{u.id}</td>
                                <td>{u.login}</td>
                                <td>{u.password}</td>
                                <td>
                                    <button onClick={() => setForm({ ...u, password: "" })}>Modif.</button>
                                    <button onClick={() => deleteUser(u.id)}>Supp.</button>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>

        </>
    )
}