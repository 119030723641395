import { Link, Outlet, useNavigate } from "react-router-dom";
import {
  MdNightlife,
  MdOutlineBedroomParent,
  MdRealEstateAgent,
  MdSpaceDashboard,
} from "react-icons/md";
import { FaHotel, FaLanguage, FaUsersCog, FaWpforms } from "react-icons/fa";
import { RiPinDistanceFill, RiVidiconFill } from "react-icons/ri";
import { BsFillCalendar3WeekFill } from "react-icons/bs";
import { IoIosRestaurant } from "react-icons/io";
import { SiMarketo } from "react-icons/si";
import { BiSpa } from "react-icons/bi";
import { useEffect } from "react";
import axios from "axios";
import { API_URL } from "../config/constants";
export default function Admin() {
  const navigate = useNavigate()
  useEffect(() => {
    const token = localStorage.getItem('token')
    if(!token) navigate("/login")
    else {
      axios.get(API_URL + "auth/verify-token", {
        headers: {
          Authorization: token
        }
      })
      .then(_ => {})
      .catch(_ => navigate('/login'))
    }
  }, [])
  return (
    <div id="admin-container">
      <nav className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
        <a className="navbar-brand col-md-3 col-lg-2 mr-0 px-3" href="#">
          Admin royal asbu
        </a>
        <button
          className="navbar-toggler position-absolute d-md-none collapsed"
          type="button"
          data-toggle="collapse"
          data-target="#sidebarMenu"
          aria-controls="sidebarMenu"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <ul className="navbar-nav px-3">
          <li className="nav-item text-nowrap">
            <Link to={"/login"} onClick={() => {
              localStorage.removeItem('token')
            }} className={"nav-link"}>Se déconnecter</Link>
          </li>
        </ul>
      </nav>
      <div id="admin-container" className="container-fluid">
        <div className="row">
          <nav
            id="sidebarMenu"
            className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse"
          >
            <div className="sidebar-sticky pt-3">
              <ul className="nav flex-column">
                <li className="nav-item">
                  <Link className="nav-link" to="/" target={"_blank"}>
                    <MdSpaceDashboard size={24} style={{ marginRight: 8 }} />
                    Redirection site client
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={"nav-link"} to={"information-hotel"}>
                    <FaHotel
                      size={18}
                      style={{ marginRight: 12, marginLeft: 4 }}
                    />
                    Informations hôtel
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={"nav-link"} to={"situation"}>
                    <RiPinDistanceFill size={24} style={{ marginRight: 8 }} />
                    Situation
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={"nav-link"} to={"hebergements"}>
                    <MdOutlineBedroomParent
                      size={24}
                      style={{ marginRight: 8 }}
                    />
                    Hebergements
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={"nav-link"} to={"conditions-chambres"}>
                    <FaWpforms size={24} style={{ marginRight: 8 }} />
                    Gestion de ventes
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={"nav-link"} to={"reservations"}>
                    <BsFillCalendar3WeekFill
                      size={18}
                      style={{ marginRight: 12, marginLeft: 4 }}
                    />
                    Réservations
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={"nav-link"} to={"installations-espaces"}>
                    <MdRealEstateAgent size={24} style={{ marginRight: 8 }} />
                    Installations et espaces
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={"nav-link"} to={"salles-conferences"}>
                    <RiVidiconFill size={24} style={{ marginRight: 8 }} />
                    Salles de conférences
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={"nav-link"} to={"gastronomie"}>
                    <IoIosRestaurant size={24} style={{ marginRight: 8 }} />
                    Gastronomie
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={"nav-link"} to={"spas"}>
                    <BiSpa size={24} style={{ marginRight: 8 }} />
                    Gestion Spa
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={"nav-link"} to={"animation-loisir"}>
                    <MdNightlife size={24} style={{ marginRight: 8 }} />
                    Animations et loisirs
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={"nav-link"} to={"marches"}>
                    <SiMarketo size={24} style={{ marginRight: 8 }} />
                    Marchés
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={"nav-link"} to={"langues"}>
                    <FaLanguage size={24} style={{ marginRight: 8 }} />
                    Gestion du contenu
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={"nav-link"} to={"users"}>
                    <FaUsersCog size={24} style={{ marginRight: 8 }} />
                    Gestion des utilisateurs
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
          <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-md-4">
            <Outlet />
          </main>
        </div>
      </div>
    </div>
  );
}
