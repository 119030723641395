import BaseService from "./base.service";
import {API_URL} from "../config/constants";
import axios from "axios";

export default class StockService extends BaseService{
    constructor() {
        super("Stock");
        this.API_URL = API_URL + "pv/stock/"
    }
    async getAll(data){
        return await
            axios
                .post(this.API_URL + "get-by-interval",data, {
                    headers:{
                        "Content-Type": "application/json"
                    }
                } )
                .then(res => res.data)
                .catch(error => error)
    }

    async updateStock(data){
        return await
            axios
                .post(this.API_URL + "update-stock", data, {
                    headers:{
                        "Content-Type": "application/json"
                    }
                })
                .then(res => res.data)
                .catch(error => error)
    }
}
