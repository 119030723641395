import axios from "axios";
import {API_URL} from "../config/constants";
import axiosInstance from "../config/authRequest";

class BaseService{
    constructor(entity = "") {
        this.entity = entity
    }

    async getAll() {
       try {
        return await axios.get(API_URL + this.entity)
        .then(res => res.data)
       } catch (error) {
        console.log(error);
        throw error.message
       }
    }

    async save(id, data){
        return await axiosInstance.put(`${API_URL}${this.entity}/${id}`, data)
            .then(res => res.data)
    }
    async create(data){
        return await axiosInstance.post(`${API_URL}${this.entity}`, data, {
            headers:{
                "content-type": "application/json"
            }
        })
            .then(res => res.data)
    }
    async findBy(by, byValue){
        return await axios.post(`${API_URL}get-by/${this.entity}`, {by, byValue}, {
            headers:{
                "content-type": "application/json"
            }
        })
            .then(res => res.data)
            .catch(error => error)
    }
    async delete(id){
        return await axiosInstance.delete(`${API_URL}${this.entity}/${id}`)
            .then(res => res.data)
    }
    async getLandingData(){
        return await axios.get(`${API_URL}general/all/get-landing-data`)
            .then(res => res.data)
            .catch(error => error)
    }

    async postContact(data) {
        return await axios
          .post(`${API_URL}general/contact/send`, data, {
            headers: {
              "content-type": "application/json",
            },
          })
          .then((res) => res.data)
          .catch((error) => error);
      }

    async saveContenu(data){
        return await 
        axiosInstance
        .post(`${API_URL}v2/contenus/save`, data, {
            headers: {
                "Content-Type": "application/json"
            }
        })
    }  
}

export default BaseService
