import {createSlice} from "@reduxjs/toolkit";
import moment from "moment";

const initialState = {
    filterForm: {
        date_debut_reservation: moment().format("YYYY-MM-DD"),
        date_fin_reservation: "",
        date_debut_sejour: "",
        date_fin_sejour: "",
        nb_nuits_sup: 0,
        nb_nuits_inf: 0
    },
    rows: []
}

const reservationsSlice = createSlice({
    name: "reservations",
    initialState,
    reducers:{
        setFilterForm: (state, action) => {
            const {field, value} = action.payload
            state.filterForm[field] = value
        },
        setRows: (state, action) => {
            state.rows = action.payload
        }
    }
})

const {setFilterForm, setRows} = reservationsSlice.actions
export const reservationsActions = {setFilterForm, setRows}
export const reservationsStateSelector = state => state.reservations
export default reservationsSlice.reducer
