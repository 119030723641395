import { useEffect, useState } from "react";
import PromotionService from "../../../../service/promotion.service";

const service = new PromotionService();
const arrangements = [
  "lpd",
  "dp",
  "dp_plus",
  "pc",
  "pc_plus",
  "ai_soft",
  "ai",
  "ultra_ai",
];
const PROMOTION_IS = {
  label: "",
  date_reservation_du: null,
  date_reservation_au: null,
  date_application_du: null,
  date_application_au: null,
};
export default function CreatePromo() {
  const [repartitions, setRepartitions] = useState([]);
  const [promotions, setPromotions] = useState([]);
  const [promotion, setPromotion] = useState(PROMOTION_IS);
  useEffect(() => {
    service.getRepartitions().then((rows) => setRepartitions(rows));
  }, [service]);

  const updateRepartition = (id_repartition, field, value) => {
    const index = promotions.findIndex(
      (p) => p.id_repartition === id_repartition
    );
    if (index === -1) {
      setPromotions([
        ...promotions,
        {
          id_repartition: id_repartition,
          [field]: value,
        },
      ]);
    } else {
      let promotionsWU = [...promotions];
      promotionsWU[index][field] = value;
      setPromotions(promotionsWU);
    }
  };

  const submit = (e) => {
    e.preventDefault();
    service.createPromotions({ promotion, promotions }).then((_) => {
      setPromotion(PROMOTION_IS);
      setPromotions([]);
    });
  };

  return (
    <div className={"row"}>
      <div className="col-12">
        <h3>Créer promotions</h3>
      </div>
      <form onSubmit={submit}>
        <div className="row">
          <div className="form-group col-12 col-md-2">
            <label htmlFor="input-label">Label</label>
            <input
              value={promotion.label}
              onChange={(e) =>
                setPromotion({ ...promotion, label: e.target.value })
              }
              type="text"
              className={"form-control"}
            />
          </div>
          <div className="form-group col-12 col-md-2">
            <label htmlFor="input-du-reservation">Date début réservation</label>
            <input
              value={promotion.date_reservation_du || ""}
              onChange={(e) =>
                setPromotion({
                  ...promotion,
                  date_reservation_du: e.target.value,
                })
              }
              type="date"
              className={"form-control"}
            />
          </div>
          <div className="form-group col-12 col-md-2">
            <label htmlFor="input-au-reservation">Date fin réservation</label>
            <input
              value={promotion.date_reservation_au || ""}
              onChange={(e) =>
                setPromotion({
                  ...promotion,
                  date_reservation_au: e.target.value,
                })
              }
              type="date"
              className={"form-control"}
            />
          </div>
          <div className="form-group col-12 col-md-2">
            <label htmlFor="input-du-application">Date début application</label>
            <input
              value={promotion.date_application_du || ""}
              onChange={(e) =>
                setPromotion({
                  ...promotion,
                  date_application_du: e.target.value,
                })
              }
              type="date"
              className={"form-control"}
            />
          </div>
          <div className="form-group col-12 col-md-2">
            <label htmlFor="input-au-application">Date fin application</label>
            <input
              value={promotion.date_application_au || ""}
              onChange={(e) =>
                setPromotion({
                  ...promotion,
                  date_application_au: e.target.value,
                })
              }
              type="date"
              className={"form-control"}
            />
          </div>
          <div className="col-12 col-md-2 d-flex align-items-end">
            <button type={"submit"} className={"btn btn-success w-100 btn p-2"}>
              <i className={"bi bi-save"} />
              Enregistrer
            </button>
          </div>
        </div>
      </form>
      <div className="col-12 mt-2">
        <table
          className={
            "table-prix-chambre table table-bordered table-hover table-striped"
          }
        >
          <thead className={"table-dark"}>
            <tr>
              <th>chambre</th>
              <th>nb_personnes</th>
              <th>LPD</th>
              <th>DP</th>
              <th>DP_PLUS</th>
              <th>PC</th>
              <th>PC_PLUS</th>
              <th>AI_SOFT</th>
              <th>AI</th>
              <th>ULTRA_AI</th>
            </tr>
          </thead>
          <tbody>
            {repartitions
              .filter((rep) => rep.actif)
              .map((r) => (
                <tr key={r.id}>
                  <td>{r.room.categorie}</td>
                  <td>{r.nb_adulte}</td>
                  {arrangements.map((a) => {
                    const repartition = promotions.find(
                      (p) => p.id_repartition === r.id
                    );
                    let value = 0;
                    if (repartition) {
                      if (repartition[a]) value = repartition[a];
                    }
                    return (
                      <td key={a}>
                        <input
                          onChange={(e) =>
                            updateRepartition(r.id, a, e.target.value)
                          }
                          type="number"
                          value={value}
                        />
                      </td>
                    );
                  })}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
