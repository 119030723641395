import { BiSave } from "react-icons/bi";
import AdminPageTitle from "../../components/admin-page-title";
import { Fragment, useEffect, useState } from "react";
import { CgSpinner } from "react-icons/cg";
import BaseService from "../../service/base.service";
const service = new BaseService("SectionContenu");
const HeadRow = () => (
  <thead style={{ background: "gray" }}>
    <tr>
      <th>Text</th>
      <th>Arabe</th>
      <th>Français</th>
      <th>Anglais</th>
    </tr>
  </thead>
);
export default function Langues() {
  const [rows, setRows] = useState([]);
  const getAll = () => {
    setLoading(true);
    service
      .getAll()
      .then((res) => setRows(res))
      .catch((error) => console.log(error))
      .finally((_) => setLoading(false));
  };
  useEffect(() => {
    getAll();
  }, []);
  const handleChangeRow = (sectionIndex, contenuIndex, lng, value) => {
    let rowsWU = [...rows];
    rowsWU[sectionIndex].contenus[contenuIndex][lng] = value;
    setRows(rowsWU);
  };
  const submit = () => {
    setLoading(true);
    service
      .saveContenu(rows)
      .then((res) =>
        alert(
          res.status === 200
            ? "Mise à jour avec succès"
            : "Erreur de mise à jour"
        )
      )
      .catch((error) => {
        alert('Erreur de mise à jour')
        console.log(error)
      })
      .finally((_) => setLoading(false));
  };
  const [loading, setLoading] = useState(false);
  return (
    <div>
      <AdminPageTitle
        title={"Gestion des langues"}
        children={
          <div>
            <button
              onClick={submit}
              disabled={loading}
              className="btn btn-primary"
            >
              {loading ? (
                <Fragment>
                  <CgSpinner />
                  Chargement...
                </Fragment>
              ) : (
                <Fragment>
                  <BiSave />
                  Enregistrer
                </Fragment>
              )}
            </button>
          </div>
        }
      />
      <div
        className="container"
        style={{
          maxHeight: "calc(100vh - (47px + 60px + 30px))",
          overflow: "auto",
        }}
      >
        <table className="table table-bordered trable-striped">
          {rows.map((r, sectionIndex) => (
            <Fragment key={r.id}>
              <thead className="table-dark">
                <tr>
                  <th colSpan={"100"}>({r.id}) {r.label}</th>
                </tr>
              </thead>
              <HeadRow />
              <tbody>
                {r.contenus?.map((c, contenuIndex) => (
                  <tr key={c.id}>
                    <td>{c.cle}</td>
                    <td>
                      <input
                        value={c.ar ?? ""}
                        type="text"
                        className="form-control"
                        onChange={(e) =>
                          handleChangeRow(
                            sectionIndex,
                            contenuIndex,
                            "ar",
                            e.target.value
                          )
                        }
                      />
                    </td>
                    <td>
                      <input
                        value={c.fr ?? ""}
                        type="text"
                        className="form-control"
                        onChange={(e) =>
                          handleChangeRow(
                            sectionIndex,
                            contenuIndex,
                            "fr",
                            e.target.value
                          )
                        }
                      />
                    </td>
                    <td>
                      <input
                        value={c.en ?? ""}
                        type="text"
                        className="form-control"
                        onChange={(e) =>
                          handleChangeRow(
                            sectionIndex,
                            contenuIndex,
                            "en",
                            e.target.value
                          )
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Fragment>
          ))}
        </table>
      </div>
    </div>
  );
}
