import AdminPageTitle from "../../components/admin-page-title";
import {BiPlus} from "react-icons/bi";
import {useEffect, useState} from "react";
import BaseService from "../../service/base.service";
import {AiFillDelete, AiFillEdit} from "react-icons/ai";
import FileUploader from "../../components/file-uploader";

const service = new BaseService("InstallationEspace")
const FORM_IS = {
    categorie: "",
    label: "",
    image: "",
    etat: ""
}
export default function GestionInstallationsEtEspaces(){
    const [rows, setRows] = useState([]);
    const [form, setForm] = useState(FORM_IS);
    useEffect(() => {
        getAll()
    }, [])

    const onEditClicked = row => {
        setForm(row)
    }

    const postEntity = () => {
        service.create(form)
            .then(res => {
                if(res) {
                    resetForm()
                    getAll()
                }
            })
            .catch(error => console.log(error))
    }
    const putEntity = () => {
        service.save(form.id, form)
            .then(res => {
                if(res) {
                    resetForm()
                    getAll()
                }
            })
            .catch(error => console.log(error))
    }
    const deleteEntity = id => {
        service.delete(id)
            .then(res => {
                if(res) getAll()
            })
            .catch(error => console.log(error))
    }
    const submit = e => {
        if(!form.id) postEntity(); else putEntity()
    }

    const resetForm = () => {
        setForm(FORM_IS)
    }
    const getAll = () => {
        service.getAll()
            .then(res => setRows(res))
            .catch(error => console.log(error))
    }
    return (
        <div>
            <AdminPageTitle title={"Installations et éspaces"}/>
            <button data-bs-toggle="modal" data-bs-target="#form-modal" className={"btn btn-success mb-2"}><BiPlus/>Ajouter</button>

            <table className={"table table-striped"}>
                <thead className={"table-dark"}>
                <tr>
                    <th>#</th>
                    <th>Catégorie</th>
                    <th>Libellé</th>
                    <th>État</th>
                    <th>Image</th>
                    <th>Actions</th>
                </tr>
                </thead>

                <tbody>
                {
                    rows.map((r,i) => (
                        <tr key={r.id}>
                            <td>{i+1}</td>
                            <td>{r.label}</td>
                            <td>{r.categorie}</td>
                            <td><span className={`badge bg-${r.etat === "En location" ? "danger" : "success"}`}>{r.etat}</span></td>
                            <td>
                                <img src={r.image} height={250} width={250} alt="is"/>
                            </td>
                            <td>
                                <button onClick={() => deleteEntity(r.id)} className={"btn btn-danger"}><AiFillDelete/>Supp.</button>
                                <button
                                    data-bs-toggle="modal" data-bs-target="#form-modal"
                                    onClick={() => {
                                    onEditClicked(r)
                                }} className={"btn btn-warning"} style={{marginLeft: 16}}><AiFillEdit/>Modif.</button>
                            </td>
                        </tr>
                    ))
                }
                </tbody>
            </table>


            <div className="modal fade" id="form-modal" tabIndex="-1" aria-labelledby="modal"
                 aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Formulaire installations et espaces</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={resetForm}/>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <label htmlFor="input-label">Label</label>
                                <input id={"input-label"} type="text" className="form-control input-sm" value={form.label} onChange={e => setForm({...form, label: e.target.value})}/>
                            </div>

                            <div className="form-group">
                                <label htmlFor="select-category">Catégorie</label>
                                <select value={form.categorie} onChange={e => setForm({...form, categorie: e.target.value})} className="form-control input-sm" id={"select-category"}>
                                    <option value="">--- veuillez choisir ---</option>
                                    <option value="Galerie esplande">Galerie esplande</option>
                                    <option value="Galerie ouest">Galerie ouest</option>
                                </select>
                            </div>

                            <div className="form-group">
                                <label htmlFor="select-etat">État</label>
                                <select value={form.etat} onChange={e => setForm({...form, etat: e.target.value})} className="form-control input-sm" id={"select-etat"}>
                                    <option value="">--- veuillez choisir ---</option>
                                    <option value="Libre">Libre</option>
                                    <option value="En location">En location</option>
                                </select>
                            </div>

                            <div className="form-group">
                                <label htmlFor="image-uploader">Image</label>
                                <FileUploader id={"image-uploader"} onUrlReceived={url => setForm({...form, image: url})}
                                              html={<label style={{height: 500, width: "100%", border: "1px solid lightgray"}} className={"d-flex justify-content-center align-items-center"} htmlFor={"image-uploader"}>
                                                  {
                                                      form.image !== "" ? <img src={form.image} height={500} width={"100%"} alt=""/> : <span>+</span>
                                                  }
                                              </label> }/>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button onClick={resetForm} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
                            <button onClick={submit} type="button" data-bs-dismiss="modal" className="btn btn-primary">Enregistrer</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
