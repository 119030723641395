import { useEffect, useState } from "react";
import moment from "moment";
import StockService from "../../../service/stock.service";
import { BiCheckDouble } from "react-icons/bi";
const stockService = new StockService();
export default function Stock() {
  const [rooms, setRooms] = useState([]);
  const [dateRange, setDateRange] = useState({ du: "", au: "" });
  const [dates, setDates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [stockGeneral, setStockGeneral] = useState(0);
  useEffect(() => {
    if (dateRange.du !== "" && dateRange.au !== "") {
      let currentDate = moment(dateRange.du);
      let endDate = moment(dateRange.au);
      let datesArray = [];
      while (currentDate <= endDate) {
        datesArray.push(currentDate.clone());
        currentDate.add(1, "days");
      }
      setDates(datesArray);
      setRooms([]);
    }
  }, [dateRange]);

  const submit = (e) => {
    e.preventDefault();
    stockService
      .getAll(dateRange)
      .then((res) => setRooms(res))
      .catch((error) => console.log(error));
  };

  const updateRoomStock = (room_id, date, value) => {
    let roomsWU = [...rooms];
    const index = rooms.findIndex((r) => r.id === room_id);
    let stockIndex = rooms[index].stock.findIndex((s) => s.date === date);
    roomsWU[index].stock[stockIndex].value = value;
    setRooms(roomsWU);
  };

  const submitUpdateStock = () => {
    setLoading(true);
    stockService.updateStock(rooms).then((res) => {
      console.log(res);
      setLoading(false);
    });
  };

  const appliquerPourTous = (e) => {
    e.preventDefault();
    let newArray = [...rooms];
    newArray.forEach((room) => {
      room.stock = room.stock.map((s) => {
        return { ...s, value: Number(stockGeneral) };
      });
    });
    setRooms(newArray);
  };
  return (
    <div>
      <div className="row">
        <h3 className={"col-12 col-md-6"}>Gestion du stock</h3>
        <form onSubmit={submit} className={"col-12 col-md-6"}>
          <div className="row">
            <div className="form-group col-12 col-md-4">
              <label htmlFor="input-du">Date du</label>
              <input
                value={dateRange.du}
                onChange={(e) =>
                  setDateRange({ ...dateRange, du: e.target.value })
                }
                type="date"
                id={"input-du"}
                className={"form-control"}
              />
            </div>
            <div className="form-group col-12 col-md-4">
              <label htmlFor="input-du">Date au</label>
              <input
                value={dateRange.au}
                onChange={(e) =>
                  setDateRange({ ...dateRange, au: e.target.value })
                }
                type="date"
                id={"input-du"}
                className={"form-control"}
              />
            </div>
            <div className="col-12 col-md-4 d-flex align-items-end">
              <button type={"submit"} className={"btn btn-primary w-100 p-2"}>
                Valider
              </button>
            </div>
          </div>
        </form>
        <form
          className="col-12 col-md-4 ms-auto mt-3 d-flex"
          onSubmit={appliquerPourTous}
        >
          <label htmlFor="stock_general" style={{ flex: 1 }}>
            Stock Général
          </label>
          <input
            onClick={(e) => e.target.select()}
            style={{ flex: 1 }}
            name="stock_general"
            id="stock_general"
            type="number"
            className="form-control"
            value={stockGeneral}
            onChange={(e) => setStockGeneral(e.target.value)}
          />
          <button>
            <BiCheckDouble />
            Appliquer
          </button>
        </form>
      </div>
      <div className="table-responsive">
        <table
          className={"mt-4 table table-striped table-hover table-bordered"}
        >
          <thead>
            <tr>
              <th>chambres/dates</th>
              {dates.map((d, i) => (
                <th key={i}>
                  {d.format("dddd")} <br />
                  {d.format("DD/MM/YYYY")}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rooms.map((r) => (
              <tr key={r.id}>
                <td>{r.categorie}</td>
                {r.stock.map((s) => (
                  <td key={s.date}>
                    <input
                      onChange={(e) =>
                        updateRoomStock(r.id, s.date, e.target.value)
                      }
                      style={{
                        width: 70,
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                      type="number"
                      value={s.value}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <button
        disabled={loading}
        onClick={submitUpdateStock}
        className={"btn btn-primary"}
        style={{ float: "right", marginTop: 16 }}
      >
        <i className={`bi bi-${loading ? "" : "check"}`} />
        Enregistrer
      </button>
    </div>
  );
}
