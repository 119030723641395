import AdminPageTitle from "../../components/admin-page-title";
import {Link, Outlet} from "react-router-dom";
import BaseService from "../../service/base.service";
import { useState } from "react";
import { useEffect } from "react";
const service = new BaseService("InformationHotel")
export default function ConditionsChambres(){
    const [maxAgeBebe, setMaxAgeBebe] = useState(null);
    const updateMaxAgeBebe = () => {
        service
        .save(1,{max_age_bebe: maxAgeBebe})
        .then(res => alert('Mise à jour avec succès'))
        .catch(error => alert(JSON.stringify(error)))
    }
    useEffect(() => {
        service
        .getAll()
        .then(res => {
            const value = res[0].max_age_bebe
            setMaxAgeBebe(value ?? 0) 
            console.log(value ?? 0);
        })
    }, [])
    return (
        <div className={"container-fluid"}>
            <AdminPageTitle title={"Conditions des chambres"}>
                {
                    maxAgeBebe !== null ? <div className="form-group">
                    <label htmlFor="input-max-age-bebe">Max age bébé</label>
                    <input value={maxAgeBebe} onChange={e => setMaxAgeBebe(e.target.value)} style={{width: 50}} type="number" id="input-max-age-bebe" />
                    <button onClick={updateMaxAgeBebe}>Valider</button>
                </div>
                : <>chargement...</>
                }
                </AdminPageTitle>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    {/*<li className="breadcrumb-item"><Link to={"conditions"} href="#">Conditions chambres</Link></li>*/}
                    <li className="breadcrumb-item" aria-current="page"><Link to={"prix-chambre"}>Prix-Chambres</Link></li>
                    {/*<li className="breadcrumb-item"><Link to={"release-date"}>Release date</Link></li>*/}
                    <li className="breadcrumb-item" aria-current="page"><Link to={"min-stay"}>Min Stay</Link></li>
                    <li className="breadcrumb-item" aria-current="page"><Link to={"stock"}>Stock</Link></li>
                    <li className="breadcrumb-item" aria-current="page"><Link to={"stop-sale"}>Stop Sale</Link></li>
                    {/*<li className="breadcrumb-item" aria-current="page"><Link to={"prix-pax"}>Prix-Pax</Link></li>*/}
                    <li className="breadcrumb-item" aria-current="page"><Link to={"promo"}>Promo</Link></li>
                </ol>
            </nav>

            <Outlet/>
        </div>
    )
}
