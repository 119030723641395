import AdminPageTitle from "../../components/admin-page-title";
import { useEffect, useState } from "react";
import BaseService from "../../service/base.service";
import { BiEdit, BiPlus, BiSave } from "react-icons/bi";
import { CgClose } from "react-icons/cg";
import { ImImage } from "react-icons/im";
import { FiDelete } from "react-icons/fi";
import FileUploader from "../../components/file-uploader";
import HebergementService from "../../service/hebergement.service";

const service = new HebergementService();
const imagesService = new BaseService("ImageHebergement");
const FORM_IS = {
  id: null,
  categorie: "",
  categorie_ar: "",
  categorie_en: "",
  description: "",
  description_ar: "",
  description_en: "",
  nombre: 0,
  superficie: "",
  nb_personnes: 0,
  debut_prix: 0,
};
export default function Situation() {
  const [rows, setRows] = useState([]);
  const [form, setForm] = useState(FORM_IS);
  const [images, setImages] = useState([]);
  const [rowForImages, setRowForImages] = useState({});
  const [repartitions, setRepartitions] = useState(null);
  const [rowForAccessories, setRowForAccessories] = useState(null);
  useEffect(() => {
    getAll();
  }, []);

  const getAll = () => {
    service
      .getAll()
      .then((rows) => setRows(rows))
      .catch((error) => console.log(error));
  };

  const submit = (e) => {
    e.preventDefault();
    if ([undefined, null, "", " ", 0, "0"].includes(form.nb_personnes))
      return alert("Nb personnes est obligatoire!");
    if (!form.id) postEntity();
    else putEntity();
  };

  const postEntity = () => {
    service
      .create(form)
      .then((res) => {
        if (res) resetForm();
      })
      .catch((error) => console.log(error));
  };
  const putEntity = () => {
    service
      .save(form.id, form)
      .then((res) => {
        if (res) resetForm();
      })
      .catch((error) => console.log(error));
  };

  const deleteEntity = (id) => {
    service
      .delete(id)
      .then((res) => {
        if (res) resetForm(false);
      })
      .catch((error) => console.log(error));
  };

  const resetForm = (reset = true) => {
    if (reset) setForm(FORM_IS);
    getAll();
  };

  const onEditClicked = (row) => {
    setForm(row);
    focusFirstInput();
  };

  const focusFirstInput = () => {
    document.getElementById("input-categorie").select();
  };

  const loadImages = (row) => {
    setRowForImages(row);
    imagesService
      .findBy("id_hebergement", row.id)
      .then((rows) => setImages(rows));
  };

  const postImage = (url) => {
    imagesService
      .create({ image: url, id_hebergement: rowForImages.id })
      .then((res) => {
        if (res) {
          loadImages(rowForImages);
        }
      });
  };
  const deleteImage = (id) => {
    imagesService.delete(id).then((res) => {
      if (res) {
        loadImages(rowForImages);
      }
    });
  };

  const updateRepartition = (index, field, value) => {
    let repartitionsWU = [...repartitions];
    repartitionsWU[index][field] = value;
    setRepartitions(repartitionsWU);
  };

  const saveRepartitions = () => {
    service.saveRepartitions(repartitions).then((res) => console.log(res));
  };
  const addAccessory = (e) => {
    let accessory = {};
    switch (e.target.value) {
      case "1":
        accessory = { image: "/assets/img/ac.png", label: "Climatisation" };
        break;
      case "2":
        accessory = { image: "/assets/img/balcony.png", label: "Blacon" };
        break;
      case "3":
        accessory = {
          image: "/assets/img/bathroom.png",
          label: "Salle de bain",
        };
        break;
      case "4":
        accessory = { image: "/assets/img/beach.png", label: "Vue mer" };
        break;
      case "5":
        accessory = { image: "/assets/img/pool.png", label: "Vue piscine" };
        break;
      case "6":
        accessory = { image: "/assets/img/minibar.png", label: "Mini bar" };
        break;
      case "7":
        accessory = { image: "/assets/img/phone.png", label: "Téléphone" };
        break;
      case "8":
        accessory = { image: "/assets/img/tv.png", label: "TV" };
        break;
      case "9":
        accessory = { image: "/assets/img/surface.png", label: "Superficie" };
        break;
      case "10":
        accessory = { image: "/assets/img/shower.png", label: "Douche" };
        break;
      case "11":
        accessory = {
          image: "/assets/img/ironing.png",
          label: "Fer  à repasser",
        };
        break;
      case "12":
        accessory = { image: "/assets/img/wifi.png", label: "Wifi" };
        break;
      case "13":
        accessory = {
          image: "/assets/img/coffee-machine.png",
          label: "Machine à café",
        };
        break;
      case "14":
        accessory = { image: "/assets/img/kettle.png", label: "Bouillard" };
        break;
      case "15":
        accessory = { image: "/assets/img/safe.png", label: "Coffre fort" };
        break;
      case "16":
        accessory = {
          image: "/assets/img/bathrobe.png",
          label: "Cape de bain",
        };
        break;
      default:
        accessory = {};
    }
    actionAccessory("add", accessory);
  };
  const actionAccessory = (action, payload) => {
    if (action === "add") {
      service
        .actionAccessory("add", {
          ...payload,
          id_hebergement: rowForAccessories.id,
        })
        .then((res) => {
          setRowForAccessories({
            ...rowForAccessories,
            room_accessories: [...rowForAccessories.room_accessories, res],
          });
          const index = rows.findIndex((r) => r.id === rowForAccessories.id);
          let rowsWU = [...rows];
          rowsWU[index].room_accessories = [
            ...rowsWU[index].room_accessories,
            res,
          ];
          setRows(rowsWU);
        });
    }
    if (action === "delete") {
      service.actionAccessory("delete", payload).then((res) => {
        setRowForAccessories({
          ...rowForAccessories,
          room_accessories: rowForAccessories.room_accessories.filter(
            (ra) => ra.id !== payload
          ),
        });
        const index = rows.findIndex((r) => r.id === rowForAccessories.id);
        let rowsWU = [...rows];
        rowsWU[index].room_accessories = rowsWU[index].room_accessories.filter(
          (ra) => ra.id !== payload
        );
        setRows(rowsWU);
      });
    }
  };

  return (
    <div>
      <AdminPageTitle title={"Hébérgements"} />
      <div>
        <form onSubmit={submit} className={"row"}>
          <div className="col-12 col-md-4">
            <div className="form-group">
              <label htmlFor="input-categorie">Label Fr</label>
              <input
                required
                value={form.categorie}
                onChange={(e) =>
                  setForm({ ...form, categorie: e.target.value })
                }
                type="text"
                className={"form-control"}
                id={"input-categorie"}
              />
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="form-group">
              <label htmlFor="input-categorie-ar">Label Ar</label>
              <input
                required
                value={form.categorie_ar}
                onChange={(e) =>
                  setForm({ ...form, categorie_ar: e.target.value })
                }
                type="text"
                className={"form-control"}
                id={"input-categorie-ar"}
              />
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="form-group">
              <label htmlFor="input-categorie-en">Label en</label>
              <input
                required
                value={form.categorie_en}
                onChange={(e) =>
                  setForm({ ...form, categorie_en: e.target.value })
                }
                type="text"
                className={"form-control"}
                id={"input-categorie_en"}
              />
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="input-description">Description</label>
              <input
                required
                value={form.description}
                onChange={(e) =>
                  setForm({ ...form, description: e.target.value })
                }
                type="text"
                className={"form-control"}
                id={"input-description"}
              />
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="input-description-ar">Description ar</label>
              <input
                required
                value={form.description_ar}
                onChange={(e) =>
                  setForm({ ...form, description_ar: e.target.value })
                }
                type="text"
                className={"form-control"}
                id={"input-description-ar"}
              />
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="input-description-en">Description en</label>
              <input
                required
                value={form.description_en}
                onChange={(e) =>
                  setForm({ ...form, description_en: e.target.value })
                }
                type="text"
                className={"form-control"}
                id={"input-description-en"}
              />
            </div>
          </div>
          <div className="col-12 col-md-1">
            <div className="form-group">
              <label htmlFor="input-nombre">Nombre</label>
              <input
                value={form.nombre}
                onChange={(e) =>
                  setForm({ ...form, nombre: Number(e.target.value) })
                }
                type="number"
                step={1}
                className={"form-control"}
                id={"input-nombre"}
              />
            </div>
          </div>
          <div className="col-12 col-md-1">
            <div className="form-group">
              <label htmlFor="input-superficie">Superficie</label>
              <input
                value={form.superficie}
                onChange={(e) =>
                  setForm({ ...form, superficie: e.target.value })
                }
                type="text"
                className={"form-control"}
                id={"input-superficie"}
              />
            </div>
          </div>
          <div className="col-12 col-md-2">
            <div className="form-group">
              <label htmlFor="input-nb-personne">Nb personnes</label>
              <input
                required
                value={form.nb_personnes}
                onChange={(e) =>
                  setForm({ ...form, nb_personnes: e.target.value })
                }
                type="number"
                step={1}
                className={"form-control"}
                id={"input-nb-personnes"}
              />
            </div>
          </div>
          <div className="col-12 col-md-2">
            <div className="form-group">
              <label htmlFor="input-debut-prix">Prix début</label>
              <input
                value={form.debut_prix}
                onChange={(e) =>
                  setForm({ ...form, debut_prix: e.target.value })
                }
                type="number"
                step={1}
                className={"form-control"}
                id={"input-debut-prix"}
              />
            </div>
          </div>
          <div className="col-12 col-md-3  d-flex">
            <div className="form-group d-flex align-items-end">
              {form.id ? (
                <div className={"d-flex"}>
                  <button type={"submit"} className={"btn btn-warning"}>
                    <BiSave /> Enregistrer
                  </button>
                  <button onClick={resetForm} className={"btn btn-error"}>
                    <CgClose />
                  </button>
                </div>
              ) : (
                <button type={"submit"} className={"btn btn-success"}>
                  <BiPlus /> Ajouter un emplacement
                </button>
              )}
            </div>
          </div>
        </form>
        <table className={"mt-2 table table-bordered table-hover table-sm"}>
          <thead className={"table-dark"}>
            <tr>
              <th width={"5%"}>#</th>
              <th width={"45%"}>Catégorie</th>
              <th width={"10%"}>Nombre</th>
              <th width={"10%"}>Superficie</th>
              <th width={"10%"}>nb_personnes</th>
              <th width={"30%"}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row, i) => (
              <tr key={row.id}>
                <td>{row.id}</td>
                <td>
                  {row.categorie}
                  <br />
                  {row.categorie_ar}
                  <br />
                  {row.categorie_en}
                </td>
                <td>{row.nombre}</td>
                <td>{row.superficie}</td>
                <td>{row.nb_personnes}</td>
                <td className={"d-flex justify-content-around"}>
                  <button
                    className={"btn btn-danger btn-sm"}
                    onClick={() => deleteEntity(row.id)}
                  >
                    <FiDelete />
                    supp.
                  </button>
                  <button
                    className={"btn btn-success"}
                    onClick={() => {
                      setRepartitions(row.repartition_hebergements);
                      setRowForAccessories(null);
                    }}
                  >
                    Rép
                  </button>
                  <button
                    className={"btn btn-info"}
                    onClick={() => {
                      setRepartitions(null);
                      setRowForAccessories(row);
                    }}
                  >
                    Acc.
                  </button>
                  {/*<button>Marchés</button>*/}
                  <button
                    className={"btn btn-warning btn-sm"}
                    onClick={() => onEditClicked(row)}
                  >
                    <BiEdit />
                    modif.
                  </button>
                  <button
                    className={"btn btn-primary btn-sm"}
                    onClick={() => loadImages(row)}
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    <ImImage />
                    Images.
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {repartitions && (
          <div className={"col-md-4 col-12"}>
            <div className="d-flex justify-content-between">
              <h3>Répartition</h3>
              <button
                className={"btn btn-danger"}
                onClick={() => setRepartitions(null)}
              >
                x
              </button>
            </div>
            <table
              className={
                "table table-striped table-bordered table-prix-chambre"
              }
            >
              <thead>
                <tr>
                  <th>#</th>
                  <th>nb_adultes</th>
                  <th>nb_enfants</th>
                  <th>max_bebes</th>
                  <th>actif</th>
                </tr>
              </thead>
              <tbody>
                {repartitions.map((r, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{r.nb_adulte}</td>
                    <td>{r.nb_enfant}</td>
                    <td>
                      <input
                        type="number"
                        value={r.nb_bebe}
                        onChange={(e) =>
                          updateRepartition(i, "nb_bebe", e.target.value)
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={r.actif}
                        onChange={(e) =>
                          updateRepartition(i, "actif", e.target.checked)
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <button
              className={"btn btn-success"}
              style={{ float: "right" }}
              onClick={saveRepartitions}
            >
              Enregistrer
            </button>
          </div>
        )}
        {rowForAccessories && (
          <div className="col-12 ps-5">
            <div className="d-flex justify-content-between align-items-center">
              <h3>Accessoires {rowForAccessories.categorie}</h3>
              <select className={"form-select"} onChange={addAccessory}>
                <option value="">--Selectionner pour ajouter--</option>
                <option value="1">Climatiseur</option>
                <option value="2">Balcon</option>
                <option value="3">Salle de bain</option>
                <option value="4">Vue mer</option>
                <option value="5">Vue piscine</option>
                <option value="6">Minibar</option>
                <option value="7">Téléphone</option>
                <option value="8">TV</option>
                <option value="9">Superficie</option>
                <option value="10">Douche</option>
                <option value="11">Fer à repasser</option>
                <option value="12">Wifi</option>
                <option value="13">Machine à café</option>
                <option value="14">Bouillard</option>
                <option value="15">Coffre fort</option>
                <option value="16">Cape de bain</option>
              </select>
              <button
                onClick={() => setRowForAccessories(null)}
                className={"btn btn-danger"}
              >
                x
              </button>
            </div>
            <table className={"table table-hover table-striped table-bordered"}>
              <thead>
                <tr>
                  <th>#</th>
                  <th>image</th>
                  <th>libellé</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {rowForAccessories.room_accessories.map((a, i) => (
                  <tr key={a.id}>
                    <td>{i + 1}</td>
                    <td>
                      <img src={a.image} height={30} alt="" />
                    </td>
                    <td>{a.label}</td>
                    <td>
                      <button onClick={() => actionAccessory("delete", a.id)}>
                        supprimer
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Images hébérgement {rowForImages.categorie}
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body d-flex justify-content-around flex-wrap">
              {images.map((i) => (
                <div
                  key={i.id}
                  style={{ height: 150, width: 150, position: "relative" }}
                >
                  <img height={"100%"} width={"100%"} src={i.image} alt="img" />
                  <button
                    onClick={() => deleteImage(i.id)}
                    className={"btn btn-danger"}
                    style={{ position: "absolute", top: 8, right: 8 }}
                  >
                    x
                  </button>
                </div>
              ))}
              <FileUploader
                html={
                  <label
                    htmlFor={"image-hebergement"}
                    className={
                      "border d-flex justify-content-center align-items-center"
                    }
                    style={{ height: 150, width: 150 }}
                  >
                    <BiPlus />
                  </label>
                }
                id={"image-hebergement"}
                onUrlReceived={postImage}
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Fermer
              </button>
              <button type="button" className="btn btn-primary">
                Enregistrer
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
