import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  langues: [],
};

const slice = createSlice({
  name: "langues",
  initialState,
  reducers: {
    setLanguages: (state, { payload }) => {
      state.langues = payload;
    },
  },
});

export const clientStateSelector = (state) => state.client;
export const { setLanguages } = slice.actions;
export default slice.reducer;
