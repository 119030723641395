import axios from 'axios';
import { API_URL } from './constants';

// Create an Axios instance with a base URL
const axiosInstance = axios.create({
  baseURL: API_URL, 
  headers: {
    "Content-Type": "application/json"
  }
});

// Add an interceptor to automatically attach the JWT token to all requests
axiosInstance.interceptors.request.use(
  (config) => {
    // Get the token from local storage
    const token = localStorage.getItem('token');

    // If a token exists, add it to the request headers
    if (token) {
      config.headers.Authorization = `${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
