import { useEffect, useState } from "react";
import AppText from "../components/app-text";
import Contact from "../contact";
import SearchForm from "../search-form";
import BaseService from "../service/base.service";
import Gallery from "../components/gallery";
import { spaI18 } from "../config/constants";
import { TbListDetails } from "react-icons/tb";
const service = new BaseService("MediaSpa");
export default function Spas() {
  const [medias, setMedias] = useState(null);
  useEffect(() => {
    service
      .getAll()
      .then((res) => setMedias(res))
      .catch((error) => console.error(error));
  }, []);

  if (!medias) return <>Chargement...</>;
  return (
    <div>
      <div
        style={{
          height: "100vh",
          background: `url('assets/img/spas/hero.avif')`,
          backgroundSize: "cover",
        }}
      >
        <div
          style={{
            height: "100%",
            background: `rgba(0,0,0,.6)`,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h2 className="container text-center" style={{ color: "white" }}>
            <p style={{ color: "var(--primary-color)" }} className="mb-0">
              {["fr", "en"].includes(localStorage.getItem("lng"))
                ? "ATHENEE"
                : "أثينا"}
            </p>

            <AppText cle={"hero_title"} />
          </h2>
          <SearchForm />
        </div>
      </div>
      <div className="container my-5">
        <h2>
          <AppText cle={"spa_titre_1"} />
        </h2>
        <p>
          <AppText cle={"spa_text_1"} />
        </p>
        <div className="row">
         <Gallery images={medias.filter(m => m.type === "image").map(m => m.src)}/>
        </div>
        <h2>
          <AppText cle={"spa_title_2"} />
        </h2>
        <div className="row g-3">
          <div className="col-6 col-md-3">
            <div className="card d-flex flex-column align-items-center">
              <img
                className="mb-4 mt-3"
                src="assets/img/spas/sauna.png"
                width={"25%"}
                alt=""
              />
              <p style={{ fontSize: "1.4rem" }}>
                <AppText cle={"sauna"} />
              </p>
            </div>
          </div>
          <div className="col-6 col-md-3">
            <div className="card d-flex flex-column align-items-center">
              <img
                className="mb-4 mt-3"
                src="assets/img/spas/oil.png"
                width={"25%"}
                alt=""
              />
              <p style={{ fontSize: "1.4rem" }}>
                <AppText cle={"oil"} />
              </p>
            </div>
          </div>
          <div className="col-6 col-md-3">
            <div className="card d-flex flex-column align-items-center">
              <img
                className="mb-4 mt-3"
                src="assets/img/spas/body-massage.png"
                width={"25%"}
                alt=""
              />
              <p style={{ fontSize: "1.4rem" }}>
                <AppText cle={"massage"} />
              </p>
            </div>
          </div>
          <div className="col-6 col-md-3">
            <div className="card d-flex flex-column align-items-center">
              <img
                className="mb-4 mt-3"
                src="assets/img/spas/skincare.png"
                width={"25%"}
                alt=""
              />
              <p style={{ fontSize: "1.4rem" }}>
                <AppText cle={"face"} />
              </p>
            </div>
          </div>

          <div className="col-12 text-center">
            <button
            onClick={() => window.open(medias.find(m => m.type === "pdf" && m.label === `brochure_${localStorage.getItem('lng')}`).src, '_blank')}
            className="app-button">
              <TbListDetails/>
              <span className="mx-2">{spaI18.downloadPrices[localStorage.getItem('lng')]}</span>
              </button>
          </div>

        </div>

      </div>
    </div>
  );
}
