import AdminPageTitle from "../../components/admin-page-title";
import {AiFillSave} from "react-icons/ai";
import FileUploader from "../../components/file-uploader";
import {useEffect, useState} from "react";
import BaseService from "../../service/base.service";
import VideoBulletsService from "../../service/video-bullets.service";
import Admin from "../index";

const service = new BaseService("InformationHotel");
const serviceVideo = new BaseService("SectionVideo");
const serviceVideoBullets = new VideoBulletsService()
const serviceWCU = new BaseService("WhyChooseUs")
const WHY_CHOOSE_US_IS = {
    title: "",
    text: "",
    title1: "",
    text1: "",
    title2: "",
    text2: "",
    title3: "",
    text3: "",
}
export default function InformationHotel(){

    const [form, setForm] = useState({
        label: "",
        sous_label: "",
        tel1: "",
        tel2: "",
        fax: "",
        email1: "",
        email2: "",
        facebook: "",
        instagram: "",
        localisation: "",
        capacite_hotel: "",
        image_qr: "",
        presentation: "",
        image_acceuil: ""
    });
    const [formVideo, setFormVideo] = useState({
        id: "",
        video: "",
        title: "",
        text: ""
    });
    const [videoBullets, setVideoBullets] = useState([]);
    const [videoUploadLoading, setVideoUploadLoading] = useState(false);
    const [whyChooseUs, setWhyChooseUs] = useState(WHY_CHOOSE_US_IS);

    useEffect(() => {
        service.getAll()
            .then(res => setForm(res[0]))
        serviceVideo
            .getAll()
            .then(res => setFormVideo(res[0]))
        serviceVideoBullets
            .getAll()
            .then(res => setVideoBullets(res))
        serviceWCU
            .getAll()
            .then(res => setWhyChooseUs(res[0] || WHY_CHOOSE_US_IS))
    }, [])

    const onInputChanged = e => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const submitVideoSection = e => {
        console.log(formVideo)
        e.preventDefault();
        serviceVideo
            .save(formVideo.id, formVideo)
            .then(res => {
                console.log('res save video: ', res)
                alert('Video enrigistré avec succès')
            })
            .catch(error => {
                console.error(error)
                alert('Erreur Video!')
            })

        serviceVideoBullets
            .saveAll(videoBullets)
            .then(res => console.log('res save video bullets: ', res))
    }

    const manageVideoBullets = (label, method, index) => {
        if(method === "add"){
            setVideoBullets([...videoBullets, {id: videoBullets.length === 0 ? 1 : Math.max.apply(Math, videoBullets.map(function(o) { return o.id; }))+1, label: ""}])
        } else if(method === "edit"){
            let bulletsWU = [...videoBullets]
            bulletsWU[index].label = label
            setVideoBullets(bulletsWU)
        } else {
            setVideoBullets(videoBullets.filter(vb => vb.id !== index))
        }
    }

    // useEffect(() => {
    //     console.log(formVideo.video)
    // }, [formVideo])

    const submit = e => {
        e.preventDefault()
        service.save(form.id, form)
            .then(res => {
                console.log(res)
                alert('Succès de mise à jour!')
            })
            .catch(error => {
                console.log(error)
                alert('Erreur!')
            })
    }

    const saveWCU = e => {
        e.preventDefault()
        if(whyChooseUs.id)
        serviceWCU
            .save(whyChooseUs.id, whyChooseUs)
            .then(res => console.log("res wcu: ", res))
        else
            serviceWCU
                .create( whyChooseUs)
                .then(res => console.log("res wcu: ", res))
    }
    return (
        <div>
            <AdminPageTitle title={"Information hotel"}/>
            <div className="container-fluid">
                <form onSubmit={submit} className={"row justify-content-center"}>
                    {/* <div className="col-12 col-md-2">
                        <div className="form-group">
                            <label htmlFor="">Titre</label>
                            <input value={form.label} name={"label"} onChange={onInputChanged} type="text" className={"form-control"}/>
                        </div>
                    </div>
                    <div className="col-12 col-md-2">
                        <div className="form-group">
                            <label htmlFor="">Sous titre</label>
                            <input value={form.sous_label} name={"sous_label"} onChange={onInputChanged}  type="text" className={"form-control"}/>
                        </div>
                    </div> */}
                    <div className="col-12 col-md-2">
                        <div className="form-group">
                            <label htmlFor="">Tél 1</label>
                            <input value={form.tel1} name={"tel1"} onChange={onInputChanged}  type="number" className={"form-control"}/>
                        </div>
                    </div>
                    {/* <div className="col-12 col-md-2">
                        <div className="form-group">
                            <label htmlFor="">Tél 2</label>
                            <input value={form.tel2} name={"tel2"} onChange={onInputChanged}  type="number" className={"form-control"}/>
                        </div>
                    </div>
                    <div className="col-12 col-md-2">
                        <div className="form-group">
                            <label htmlFor="">Fax</label>
                            <input value={form.fax} name={"fax"} onChange={onInputChanged}  type="number" className={"form-control"}/>
                        </div>
                    </div> */}
                    <div className="col-12 col-md-3">
                        <div className="form-group">
                            <label htmlFor="">E-mail 1</label>
                            <input value={form.email1} onChange={onInputChanged}  name={"email1"} type="email" className={"form-control"}/>
                        </div>
                    </div>
                    {/* <div className="col-12 col-md-3">
                        <div className="form-group">
                            <label htmlFor="">E-mail 2</label>
                            <input value={form.email2}  onChange={onInputChanged} name={"email2"} type="email" className={"form-control"}/>
                        </div>
                    </div> */}
                    <div className="col-12 col-md-3">
                        <div className="form-group">
                            <label htmlFor="">Facebook</label>
                            <input value={form.facebook} onChange={onInputChanged}  name={"facebook"} type="text" className={"form-control"}/>
                        </div>
                    </div>
                    <div className="col-12 col-md-3">
                        <div className="form-group">
                            <label htmlFor="">Instagram</label>
                            <input value={form.instagram} name={"instagram"}  onChange={onInputChanged} type="text" className={"form-control"}/>
                        </div>
                    </div>
                    {/* <div className="col-12 col-md-8">
                        <div className="form-group">
                            <label htmlFor="">Localisation</label>
                            <input value={form.localisation}  onChange={onInputChanged} name={"localisation"} type="text" className={"form-control"}/>
                        </div>
                    </div> */}
                    {/* <div className="col-12 col-md-2">
                        <div className="form-group">
                            <label htmlFor="">Capacité hôtel</label>
                            <input value={form.capacite_hotel}  onChange={onInputChanged} name={"capacite_hotel"} type="number" step={1} className={"form-control"}/>
                        </div>
                    </div> */}


                    {/* <div className="col-12 col-md-3">
                        <div className="form-group">
                            <label>Image QR</label>
                            <FileUploader onUrlReceived={image_qr => setForm({...form, image_qr})} id={"image-qr"}
                                          html={<label htmlFor={"image-qr"} style={{overflow: "hidden",borderRadius: 8,height: 255,width: "100%", border: "1px solid lightgray"}}>
                                              <img width={"100%"} height={"100%"} src={form.image_qr} alt=""/>
                                          </label>}/>
                        </div>
                    </div> */}
                    {/* <div className="col-12 col-md-7">
                        <div className="form-group">
                            <label>Présentation</label>
                            <textarea value={form.presentation} onChange={onInputChanged}  name="presentation" id="" cols="30" rows="10" className={"form-control"}/>
                        </div>
                    </div> */}

                    <div className="col-12 col-md-11">
                        <div className="form-group">
                            <label>Image d'acceuil</label>
                            <FileUploader onUrlReceived={image_acceuil => setForm({...form, image_acceuil})} id={"image-acceuil"}
                                          html={<label htmlFor={"image-acceuil"} style={{overflow: "hidden",borderRadius: 8,height: 600,width: "100%", border: "1px solid lightgray"}}>
                                              <img width={"100%"} height={"100%"} src={form.image_acceuil} alt=""/>
                                          </label>}/>
                        </div>
                    </div>

                    <div className="col-11 mb-5 d-flex justify-content-end">
                        <button className={"btn btn-primary"}><AiFillSave/>Enregistrer</button>
                    </div>
                </form>
            </div>

            <AdminPageTitle title={"Section video"}/>
            <div className="container mb-4">
                <form onSubmit={submitVideoSection}>
                    <div className="row justify-content-center">
                        <div className="col-11">
                            <FileUploader setLoading={loadingState => setVideoUploadLoading(loadingState)} id={"video-uploader"} onUrlReceived={video => {
                                setFormVideo({...formVideo, video})
                            }} html={
                                !videoUploadLoading ? <label htmlFor={"video-uploader"} style={{overflow: "hidden",borderRadius: 8,height: 500,width: "100%", border: "1px solid lightgray"}}>
                                <label className="btn btn-primary mb-3" htmlFor={"video-uploader"}>Upload Video</label>  
                                {
                                    formVideo.video ?
                                    <video width="100%" height="450" controls>
                                        <source src={formVideo.video} type="video/mp4"/>
                                    </video>
                                    : <p style={{height: 500}}>Chargement</p>
                                }
                            </label>
                            : <p style={{height: 500}}>Chargement...</p>    
                        }/>
                        </div>
                        {/* <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="">Titre</label>
                                <input onChange={e => setFormVideo({...formVideo, title: e.target.value})} type="text" className={"form-control"}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Text</label>
                                <textarea onChange={e => setFormVideo({...formVideo, text: e.target.value})} rows={9} className={"form-control"}></textarea>
                            </div>
                        </div>

                        <div className="col-12">
                            <h3>Points: <button className={"btn btn-success btn-sm"} type={"button"} onClick={() => manageVideoBullets(null,"add", null)}>+</button></h3>
                            {

                                videoBullets.map((vb,i) => {
                                    return (
                                        <div key={i} className={"d-flex col-12 col-md-4"}>
                                            <input value={vb.label} className={"form-control"} type="text" onChange={e => manageVideoBullets(e.target.value, "edit", i)}/> <button className={"btn"} onClick={() => manageVideoBullets(null,"delete", vb.id)}>x</button>
                                        </div>
                                    )
                                })
                            }
                        </div> */}

                        <div className="col-11 d-flex justify-content-end">
                            <button className={"btn btn-primary"}><AiFillSave/>Enregistrer</button>
                        </div>
                    </div>
                </form>
            </div>

            {/* <AdminPageTitle title={"Pourquoi nous choisir"}/>
            <div className="container mb-4">
                <form onSubmit={saveWCU}>
                    <div className="row">

                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label htmlFor="">Titre</label>
                                <input value={whyChooseUs.title} onChange={e => setWhyChooseUs({...whyChooseUs, title: e.target.value})} type="text" className={"form-control"}/>
                            </div>
                        </div>
                        <div className="col-12 col-md-8">
                            <div className="form-group">
                                <label htmlFor="">Text</label>
                                <input value={whyChooseUs.text} onChange={e => setWhyChooseUs({...whyChooseUs, text: e.target.value})} type="text" className={"form-control"}/>
                            </div>
                        </div>

                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label htmlFor="">Titre1</label>
                                <input value={whyChooseUs.title1} onChange={e => setWhyChooseUs({...whyChooseUs, title1: e.target.value})} type="text" className={"form-control"}/>
                            </div>
                        </div>
                        <div className="col-12 col-md-8">
                            <div className="form-group">
                                <label htmlFor="">Text1</label>
                                <input value={whyChooseUs.text1} onChange={e => setWhyChooseUs({...whyChooseUs, text1: e.target.value})} type="text" className={"form-control"}/>
                            </div>
                        </div>

                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label htmlFor="">Titre2</label>
                                <input value={whyChooseUs.title2} onChange={e => setWhyChooseUs({...whyChooseUs, title2: e.target.value})} type="text" className={"form-control"}/>
                            </div>
                        </div>
                        <div className="col-12 col-md-8">
                            <div className="form-group">
                                <label htmlFor="">Text2</label>
                                <input value={whyChooseUs.text2} onChange={e => setWhyChooseUs({...whyChooseUs, text2: e.target.value})} type="text" className={"form-control"}/>
                            </div>
                        </div>

                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label htmlFor="">Titre3</label>
                                <input value={whyChooseUs.title3} onChange={e => setWhyChooseUs({...whyChooseUs, title3: e.target.value})} type="text" className={"form-control"}/>
                            </div>
                        </div>
                        <div className="col-12 col-md-8">
                            <div className="form-group">
                                <label htmlFor="">Text3</label>
                                <input value={whyChooseUs.text3} onChange={e => setWhyChooseUs({...whyChooseUs, text3: e.target.value})} type="text" className={"form-control"}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mb-5 mt-4 d-flex justify-content-end">
                        <button className={"btn btn-primary"}><AiFillSave/>Enregistrer</button>
                    </div>
                </form>
            </div> */}
        </div>
    )
}
