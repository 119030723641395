import { Fragment, useEffect, useState } from "react";
import AppText from "../components/app-text";
import BaseService from "../service/base.service";
import SearchForm from "../search-form";
import { conferenceI18 } from "../config/constants";
const service = new BaseService("SalleConference");
const serviceContact = new BaseService("InformationHotel");
export default function SalleConference() {
  const [rows, setRows] = useState([]);
  const [contactData, setContactData] = useState(null);
  useEffect(() => {
    service.getAll().then((res) => setRows(res));
    service
      .getLandingData()
      .then((res) => setContactData(res.informationsHotel));
  }, []);
  if (rows.length === 0 || !contactData) return <AppText cle={"chargement"} />;
  return (
    <Fragment>
      <div
        style={{
          height: "100vh",
          background: `url('assets/img/salles/conference.jpg')`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "bottom",
        }}
      >
        <div
          style={{
            height: "100%",
            background: `rgba(0,0,0,.6)`,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <h2 className="container text-center" style={{ color: "white" }}>
            <AppText cle={"titre"} sectionLabel="salle de conférence" />
            <img height={100} src="assets/img/logo-Royal-ASBU.png" alt="" />
          </h2>
          <SearchForm />
        </div>
      </div>
      <div className="container my-5">
        <div className="row bg-light">
          <div className="col-12 text-center mt-5">
            <h2>
              <AppText
                cle={"sous_titre1"}
                sectionLabel={"salle de conférence"}
              />
            </h2>
            <p>
              <AppText
                cle={"text_section_1"}
                sectionLabel={"salle de conférence"}
              />
            </p>
            {/* <div className="row my-5">
              <div className="col-12 col-md-4 d-flex flex-column align-items-center">
                <img
                  width={"120"}
                  src="assets/img/salles/meetings.png"
                  alt=""
                />
                <h2>
                  <strong>
                    {" "}
                    <AppText
                      cle={"meetings"}
                      sectionLabel={"salle de conférence"}
                    />
                  </strong>
                </h2>
                <p>
                  <AppText
                    cle={"meetings_description"}
                    sectionLabel={"salle de conférence"}
                  />
                </p>
              </div>
              <div className="col-12 col-md-4 d-flex flex-column align-items-center">
                <img
                  width={"120"}
                  src="assets/img/salles/weddings.png"
                  alt=""
                />
                <h2>
                  <strong>
                    <AppText
                      cle={"fêtes"}
                      sectionLabel={"salle de conférence"}
                    />
                  </strong>
                </h2>
                <p>
                  <AppText
                    cle={"fêtes_description"}
                    sectionLabel={"salle de conférence"}
                  />
                </p>
              </div>
              <div className="col-12 col-md-4 d-flex flex-column align-items-center">
                <img
                  width={"120"}
                  src="assets/img/salles/seminars.png"
                  alt=""
                />
                <h2>
                  <strong>
                    <AppText
                      cle={"seminaires"}
                      sectionLabel={"salle de conférence"}
                    />
                  </strong>
                </h2>
                <p>
                  <AppText
                    cle={"seminaires_description"}
                    sectionLabel={"salle de conférence"}
                  />
                </p>
              </div>
            </div> */}
          </div>
        </div>

        <div className="row my-5">
          <div className="col-12 col-md-6">
            <img width={"100%"} height={"100%"} src={rows[0].image} alt="" />
          </div>
          <div
            className="col-12 col-md-6 p-3"
            style={{ background: "var(--secondary-color)" }}
          >
            <h2>
              <strong style={{ color: "var(--primary-color)" }}>
                <AppText cle={"nom_salle_conférence"} />
              </strong>
            </h2>
            {/* <ul style={{ color: "white" }}>
              <li>
                <strong>
                  <AppText cle={"Théatre"} />:{" "}
                </strong>{" "}
                {rows[0]?.theatre}
              </li>
              <li>
                <strong>
                  <AppText cle={"Tables rondes"} />:{" "}
                </strong>{" "}
                {rows[0]?.tables_rondes}
              </li>
              <li>
                <strong>
                  <AppText cle={"Cocktails"} />:{" "}
                </strong>{" "}
                {rows[0]?.cocktails}
              </li>
            </ul> */}
            <div className="table-responsive">
              <table
                className="table table-bordered"
                style={{ background: "white" }}
              >
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Oulaya 1+2+3</th>
                    <th>Oulaya 1+2</th>
                    <th>Oulaya 1</th>
                    <th>Oulaya 2</th>
                    <th>Oulaya 3</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td align="center">
                      <img src="/assets/img/surface.png" alt="" />
                    </td>
                    <td>750</td>
                    <td>440</td>
                    <td>220</td>
                    <td>220</td>
                    <td>260</td>
                  </tr>
                  <tr>
                    <td align="center">
                      <img
                        height={40}
                        src="/assets/img/salles/u-shape.png"
                        alt=""
                      />
                    </td>
                    <td>110</td>
                    <td>60</td>

                    <td>35</td>
                    <td>45</td>
                    <td>45</td>
                  </tr>
                  <tr>
                    <td align="center">
                      <img
                        height={40}
                        src="/assets/img/salles/classroom-shape.png"
                        alt=""
                      />
                    </td>
                    <td>150</td>
                    <td>70</td>

                    <td>45</td>
                    <td>60</td>
                    <td>60</td>
                  </tr>
                  <tr>
                    <td align="center">
                      <img
                        height={40}
                        src="/assets/img/salles/cocktail-shape.png"
                        alt=""
                      />
                    </td>
                    <td>650</td>
                    <td>120</td>

                    <td>30</td>
                    <td>70</td>
                    <td>50</td>
                  </tr>
                  <tr>
                    <td align="center">
                      <img
                        height={40}
                        src="/assets/img/salles/theature-shape.png"
                        alt=""
                      />
                    </td>
                    <td>650</td>
                    <td>300</td>

                    <td>50</td>
                    <td>60</td>
                    <td>70</td>
                  </tr>

                  <tr>
                    <td align="center">
                      <img
                        height={40}
                        src="/assets/img/salles/round-shape.png"
                        alt=""
                      />
                    </td>
                    <td>350</td>
                    <td>180</td>

                    <td>40</td>
                    <td>40</td>
                    <td>60</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p className="mb-0 mt-2" style={{ color: "var(--primary-color)" }}>
              <AppText cle={"Réservez sur:"} />{" "}
            </p>
            <div style={{ color: "white" }}>
              <strong>
                <AppText cle={"email"} />:
              </strong>{" "}
              {contactData.email1}
            </div>
            <div style={{ color: "white" }}>
              <strong>
                <AppText cle={"Numéro téléphone"} />:
              </strong>{" "}
              +216 {contactData.tel1}
            </div>
          </div>
        </div>
        <div className="row my-5">
          <div className="order-flex-2 col-12 col-md-5 bg-light p-3">
            <h2 style={{ color: "var(--primary-color)" }}>
              <strong>
                <AppText cle={"nom_salle_fêtes"} />
              </strong>
            </h2>
            {/* <ul>
              <li>
                <strong>
                  <AppText cle={"Théatre"} />:{" "}
                </strong>{" "}
                {rows[1]?.theatre}
              </li>
              <li>
                <strong>
                  <AppText cle={"Tables rondes"} />:{" "}
                </strong>{" "}
                {rows[1]?.tables_rondes}
              </li>
              <li>
                <strong>
                  <AppText cle={"Cocktails"} />:{" "}
                </strong>{" "}
                {rows[1]?.cocktails}
              </li>
            </ul> */}
            <div className="table-responsive">
              <table
                className="table table-bordered"
                style={{ background: "white" }}
              >
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Feyrouz 1+2+3</th>
                    <th>Feyrouz 1+2</th>
                    <th>Feyrouz 1</th>
                    <th>Feyrouz 2</th>
                    <th>Feyrouz 3</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td align="center">
                      <img src="/assets/img/surface.png" alt="" />
                    </td>
                    <td>180</td>
                    <td>140</td>
                    <td>80</td>
                    <td>60</td>
                    <td>40</td>
                  </tr>
                  <tr>
                    <td align="center">
                      <img
                        height={40}
                        src="/assets/img/salles/u-shape.png"
                        alt=""
                      />
                    </td>
                    <td>110</td>
                    <td>60</td>

                    <td>35</td>
                    <td>45</td>
                    <td>45</td>
                  </tr>
                  <tr>
                    <td align="center">
                      <img
                        height={40}
                        src="/assets/img/salles/classroom-shape.png"
                        alt=""
                      />
                    </td>
                    <td>150</td>
                    <td>70</td>

                    <td>45</td>
                    <td>60</td>
                    <td>60</td>
                  </tr>
                  <tr>
                    <td align="center">
                      <img
                        height={40}
                        src="/assets/img/salles/cocktail-shape.png"
                        alt=""
                      />
                    </td>
                    <td>650</td>
                    <td>120</td>

                    <td>30</td>
                    <td>70</td>
                    <td>50</td>
                  </tr>
                  <tr>
                    <td align="center">
                      <img
                        height={40}
                        src="/assets/img/salles/theature-shape.png"
                        alt=""
                      />
                    </td>
                    <td>650</td>
                    <td>300</td>

                    <td>50</td>
                    <td>60</td>
                    <td>70</td>
                  </tr>
                  <tr>
                    <td align="center">
                      <img
                        height={40}
                        src="/assets/img/salles/round-shape.png"
                        alt=""
                      />
                    </td>
                    <td>350</td>
                    <td>180</td>

                    <td>40</td>
                    <td>40</td>
                    <td>60</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p>
              <AppText cle={"Réservez sur:"} />
            </p>
            <div>
              <strong>
                <AppText cle={"email"} />:
              </strong>{" "}
              {contactData.email1}
            </div>
            <div>
              <strong>
                <AppText cle={"Numéro téléphone"} />:
              </strong>{" "}
              +216 {contactData.tel1}
            </div>
          </div>
          <div className="order-flex-1 col-12 col-md-7">
            <img width={"100%"} height={"100%"} src={rows[1].image} alt="" />
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-12 col-md-7">
            <h2>
              <AppText
                cle={"events_titre_services_supplements"}
                sectionLabel={"salle de conférence"}
              />
            </h2>

            <ul>
              <li>{conferenceI18.paperBoard[localStorage.getItem("lng")]}</li>
              <li>
                {conferenceI18.ecranProjection[localStorage.getItem("lng")]}
              </li>
              <li>
                {conferenceI18.videoProjecteur[localStorage.getItem("lng")]}
              </li>
              <li>{conferenceI18.sonorisation[localStorage.getItem("lng")]}</li>
              <li>
                {
                  conferenceI18.traductionSimultanee[
                    localStorage.getItem("lng")
                  ]
                }
              </li>
              <li>{conferenceI18.pausesCafe[localStorage.getItem("lng")]}</li>
              <li>{conferenceI18.cocktail[localStorage.getItem("lng")]}</li>
              <li>{conferenceI18.repasBuffet[localStorage.getItem("lng")]}</li>
            </ul>
          </div>
          <div className="col-12 col-md-5">
            <h2>{conferenceI18.enGratuite[localStorage.getItem("lng")]}</h2>
            <ul>
              <li>{conferenceI18.blocNotes[localStorage.getItem("lng")]}</li>
              <li>{conferenceI18.stylos[localStorage.getItem("lng")]}</li>
            </ul>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
