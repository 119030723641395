import { Fragment, useEffect, useState } from "react";
import AppText from "../components/app-text";
import BaseService from "../service/base.service";
import Contact from "../contact";
import { useLocation } from "react-router-dom";
import SearchForm from "../search-form";
import AppModal from "../components/app-modal";
import Gallery from "../components/gallery";
const service = new BaseService("InformationHotel");

export default function Acceuil() {
  const [landingData, setLandingData] = useState();
  const pathName = useLocation();
  const [galleryLoaded, setGalleryLoaded] = useState(false);
  const [contactLoaded, setContactLoaded] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  useEffect(() => {
    const section = document.getElementById(pathName.hash.slice(1));
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  }, [pathName, galleryLoaded, contactLoaded]);
  useEffect(() => {
    service.getLandingData().then((res) => setLandingData(res));
  }, []);
  if (!landingData)
    return (
      <p>
        <AppText cle={"chargement"} />
      </p>
    );

  return (
    <Fragment>
      <section id="hero">
        {showVideo && (
          <div className="app-modal-container" style={{ zIndex: 9999 }}>
            <div
              className="app-modal-content"
              style={{ position: "relative", width: "60%" }}
            >
              <button
                style={{
                  position: "absolute",
                  top: 30,
                  right: 30,
                  background: "white",
                  outline: "none",
                  border: "none",
                  width: 40,
                  height: 40,
                  borderRadius: "50%!important",
                  zIndex: 9999,
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowVideo(false);
                }}
              >
                x
              </button>
              <video width="100%" height="auto" controls autoPlay>
                <source src={landingData.sectionVideo.video} type="video/mp4" />
              </video>
            </div>
          </div>
        )}
        <div className="hero-container">
          <div
            id="heroCarousel"
            data-bs-interval="5000"
            className="carousel slide carousel-fade"
            data-bs-ride="carousel"
          >
            <div className="carousel-inner" role="listbox">
              <div
                className="carousel-item active"
                style={{
                  backgroundImage: `url(${landingData.informationsHotel.image_acceuil})`,
                }}
              >
                <div className="carousel-container">
                  <div className="carousel-content">
                    <h2 className="animate__animated animate__fadeInDown">
                      <AppText cle={"text_acceuil"} />
                      <img
                        height={120}
                        src="assets/img/logo-Royal-ASBU.png"
                        alt=""
                      />
                    </h2>
                    <p className="animate__animated animate__fadeInUp">
                      {landingData.informationsHotel.presentation}
                    </p>
                  </div>
                </div>
              </div>
              <SearchForm />
            </div>
          </div>
        </div>
      </section>
      <main id="main">
        <section id="about" className="about">
          <div className="container-fluid">
            <div className="row">
              <div
                className="col-lg-5 align-items-stretch video-box"
                style={{ backgroundImage: `url('assets/img/about.jpg')` }}
              >
                <a
                  href="#"
                  className="venobox play-btn mb-4"
                  onClick={() => setShowVideo(true)}
                />
              </div>

              <div className="col-lg-7 d-flex flex-column justify-content-center align-items-stretch">
                <div className="content">
                  <h3>
                    <AppText cle={"titre video"} />
                  </h3>
                  <p>
                    <AppText cle={"text video"} />
                  </p>

                  <ul>
                    {landingData.sectionVideoBullets.map((b) => (
                      <li key={b.id}>
                        <i className="bx bx-check-double"></i>
                        {b.label}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/*    why choose us*/}
        {/* <section id="why-us" className="why-us">
          <div className="container">
            <div className="section-title">
              <h2>
                <AppText cle={"pnc_titre"} />{" "}
                <img height={90} src="assets/img/logo-Royal-ASBU.png" alt="" />
              </h2>
              <p>
                <AppText cle={"pnc_sous_titre"} />
              </p>
            </div>

            <div className="row">
              <div className="col-lg-4">
                <div className="box">
                  <span>01</span>
                  <h4>
                    <AppText cle={"pnc_t1"} />
                  </h4>
                  <p>
                    <AppText cle={"pnc_d1"} />
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="box">
                  <span>02</span>
                  <h4>
                    <AppText cle={"pnc_t2"} />
                  </h4>
                  <p>
                    <AppText cle={"pnc_d2"} />
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="box">
                  <span>03</span>
                  <h4>
                    <AppText cle={"pnc_t3"} />
                  </h4>
                  <p>
                    <AppText cle={"pnc_d3"} />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/*    gallery*/}
        <section
          onLoad={() => setGalleryLoaded(true)}
          id="gallery"
          className="gallery"
        >
          <div className="container-fluid">
            <div className="section-title">
              <h2>
                <AppText cle={"photos"} />{" "}
                <img height={90} src="assets/img/logo-Royal-ASBU.png" alt="" />
              </h2>
              <p>
                <AppText cle={"photo_st"} />
              </p>
            </div>
          </div>
          <Gallery
          imageHeight={350}
            images={[
              "assets/img/g1.webp",
              "assets/img/spa_gallery.jpg",
              "assets/img/resto_gallery.jpg",
              "assets/img/room-gallery.jfif",
              "assets/img/welcome.jfif",
              "assets/img/g6.webp",
              "assets/img/reception_spa_gallery.jpg",
              "assets/img/spa2_gallery.jpg",
              "assets/img/g7.webp",
            ]}
          />
        </section>

        {/*    contact*/}
        <Contact
          onLoad={() => setContactLoaded(true)}
          data={landingData.informationsHotel}
          service={service}
        />
      </main>
    </Fragment>
  );
}
