import AdminPageTitle from "../../components/admin-page-title";
import {BiPlus} from "react-icons/bi";
import {useEffect, useState} from "react";
import BaseService from "../../service/base.service";
import {AiFillDelete, AiFillEdit} from "react-icons/ai";
import FileUploader from "../../components/file-uploader";

const service = new BaseService("SalleConference")
const FORM_IS = {
    label: "",
    theatre: "",
    class_room: "",
    tables_rondes: "",
    cocktails: "",
    superficie: "",
    image: ""

}
export default function GestionSallesConferences(){
    const [rows, setRows] = useState([]);
    const [form, setForm] = useState(FORM_IS);
    useEffect(() => {
        getAll()
    }, [])

    const onEditClicked = row => {
        setForm(row)
    }

    const postEntity = () => {
        service.create(form)
            .then(res => {
                if(res) {
                    resetForm()
                    getAll()
                }
            })
            .catch(error => console.log(error))
    }
    const putEntity = () => {
        service.save(form.id, form)
            .then(res => {
                if(res) {
                    resetForm()
                    getAll()
                }
            })
            .catch(error => console.log(error))
    }
    const deleteEntity = id => {
        service.delete(id)
            .then(res => {
                if(res) getAll()
            })
            .catch(error => console.log(error))
    }
    const submit = e => {
        if(!form.id) postEntity(); else putEntity()
    }

    const resetForm = () => {
        setForm(FORM_IS)
    }
    const getAll = () => {
        service.getAll()
            .then(res => setRows(res))
            .catch(error => console.log(error))
    }
    return (
        <div>
            <AdminPageTitle title={"Salles de conférences"}/>
            <button data-bs-toggle="modal" data-bs-target="#form-modal" className={"btn btn-success mb-2"}><BiPlus/>Ajouter</button>

            <table className={"table table-striped"}>
                <thead className={"table-dark"}>
                <tr>
                    <th>#</th>
                    <th>Libellé</th>
                    <th>Théatre</th>
                    <th>Class room</th>
                    <th>Tables rondes</th>
                    <th>Cocktails</th>
                    <th>Superifice</th>
                    <th>Image</th>
                    <th>Actions</th>
                </tr>
                </thead>

                <tbody>
                {
                    rows.map((r,i) => (
                        <tr key={r.id}>
                            <td>{i+1}</td>
                            <td>{r.label}</td>
                            <td>{r.theatre}</td>
                            <td>{r.class_room}</td>
                            <td>{r.tables_rondes}</td>
                            <td>{r.cocktails}</td>
                            <td>{r.superficie}</td>
                            <td>
                                <img src={r.image} height={250} width={250} alt="is"/>
                            </td>
                            <td>
                                <button onClick={() => deleteEntity(r.id)} className={"btn btn-danger"}><AiFillDelete/>Supp.</button>
                                <button
                                    data-bs-toggle="modal" data-bs-target="#form-modal"
                                    onClick={() => {
                                        onEditClicked(r)
                                    }} className={"btn btn-warning"} style={{marginLeft: 16}}><AiFillEdit/>Modif.</button>
                            </td>
                        </tr>
                    ))
                }
                </tbody>
            </table>


            <div className="modal fade" id="form-modal" tabIndex="-1" aria-labelledby="modal"
                 aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Formulaire installations et espaces</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={resetForm}/>
                        </div>
                        <div className="modal-body row">

                            <div className="col-12">
                                <div className="form-group">
                                    <label htmlFor="input-label">Label</label>
                                    <input id={"input-label"} type="text" className="form-control input-sm" value={form.label} onChange={e => setForm({...form, label: e.target.value})}/>
                                </div>
                            </div>

                            <div className="col-12 col-md-6">
                                <div className="form-group">
                                    <label htmlFor="input-theatre">Théatre</label>
                                    <input id={"input-theatre"} type="text" className="form-control input-sm" value={form.theatre} onChange={e => setForm({...form, theatre: e.target.value})}/>
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="form-group">
                                    <label htmlFor="input-class-room">Class room</label>
                                    <input id={"input-class-room"} type="text" className="form-control input-sm" value={form.class_room} onChange={e => setForm({...form, class_room: e.target.value})}/>
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="form-group">
                                    <label htmlFor="input-tables-rondes">Tables rondes</label>
                                    <input id={"input-tables-rondes"} type="text" className="form-control input-sm" value={form.tables_rondes} onChange={e => setForm({...form, tables_rondes: e.target.value})}/>
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="form-group">
                                    <label htmlFor="input-cocktails">Cocktails</label>
                                    <input id={"input-cocktails"} type="text" className="form-control input-sm" value={form.cocktails} onChange={e => setForm({...form, cocktails: e.target.value})}/>
                                </div>
                            </div>


                            <div className="form-group">
                                <label htmlFor="input-superficie">Superficie</label>
                                <input id={"input-superficie"} type="text" className="form-control input-sm" value={form.superficie} onChange={e => setForm({...form, superficie: e.target.value})}/>
                            </div>



                            <div className="form-group">
                                <label htmlFor="image-uploader">Image</label>
                                <FileUploader id={"image-uploader"} onUrlReceived={url => setForm({...form, image: url})}
                                              html={<label style={{height: 350, width: "100%", border: "1px solid lightgray"}} className={"d-flex justify-content-center align-items-center"} htmlFor={"image-uploader"}>
                                                  {
                                                      form.image !== "" ? <img src={form.image} height={"100%"} width={"100%"} alt=""/> : <span>+</span>
                                                  }
                                              </label> }/>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button onClick={resetForm} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
                            <button onClick={submit} type="button" data-bs-dismiss="modal" className="btn btn-primary">Enregistrer</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
