import axios from "axios";
import {API_URL} from "../config/constants";
import BaseService from "./base.service";

class HebergementService extends BaseService{
    constructor() {
        super("Hebergement");
        this.API_URL = API_URL + "hebergement/"
    }

    async getAll(){
        return await axios
            .get(this.API_URL)
            .then(res => res.data)
    }

    async create(data){
        return await axios.post(this.API_URL, data, {
            headers:{
                "content-type": "application/json"
            }
        })
            .then(res => res.data)
            .catch(error => error)
    }

    async saveRepartitions(data){
        return await
            axios
                .put(this.API_URL + "repartitions", data, {
                    headers:{
                        "Content-Type": "application/json"
                    }
                })
                .then(res => res.data)
                .catch(error => error)
    }
    async savePirx(data){
        return await
            axios
                .put(this.API_URL + "prix", data, {
                    headers:{
                        "Content-Type": "application/json"
                    }
                })
                .then(res => res.data)
                .catch(error => error)
    }
    async getFilteredPrices(data){
        return await
            axios
                .post(this.API_URL + "filtered", data, {
                    headers:{
                        "Content-Type": "application/json"
                    }
                })
                .then(res => res.data)
                .catch(error => error)
    }
    async actionAccessory(action, payload){
        return await
            axios
                .post(this.API_URL + "action-accessory", {action, payload}, {
                    headers:{
                        "Content-Type": "application/json"
                    }
                })
                .then(res => res.data)
                .catch(error => error)
    }
}

export default HebergementService
