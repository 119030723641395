import BaseService from "./base.service";
import {API_URL} from "../config/constants";
import axios from "axios";

export default class StopSaleService extends BaseService{
    constructor() {
        super();
        this.API_URL = API_URL + "pv/stop-sale/"
    }

    async getData(){
        return await
            axios
                .get(this.API_URL + "get-data")
                .then(res => res.data)
                .catch(error => error)
    }

    async saveData(data){
        return await
            axios
                .post(this.API_URL + "save-data",data, {
                    headers:{
                        "Content-Type": "application/json"
                    }
                } )
                .then(res => res.data)
                .catch(error => error)
    }

    async delete(id){
        return await
            axios
                .delete(this.API_URL + "delete/" + id)
                .then(res => res.data)
                .catch(error => error)
    }
}
