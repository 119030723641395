import AdminPageTitle from "../../components/admin-page-title";
import {useEffect, useState} from "react";
import BaseService from "../../service/base.service";
import {BiPlus, BiSave} from "react-icons/bi";
import {CgClose} from "react-icons/cg";

const service = new BaseService("Marche")
const FORM_IS = {
    id: null,
    label: ""
}
export default function Marche(){
    const [rows, setRows] = useState([]);
    const [form, setForm] = useState(FORM_IS);
    useEffect(() => {
        getAll()
    }, [])

    const getAll = () => {
        service.getAll()
            .then(rows => setRows(rows))
            .catch(error => console.log(error))
    }

    const submit = e => {
        e.preventDefault()
        if(!form.id) postEntity()
        else putEntity()

    }
    const postEntity = () => {
        service.create(form)
            .then(res => {
                if(res) resetForm()
            })
            .catch(error => console.log(error))
    }
    const putEntity = () => {
        service.save(form.id, form)
            .then(res => {
                if(res) resetForm()
            })
            .catch(error => console.log(error))
    }

    const deleteEntity = id => {
        service.delete(id)
            .then(res => {
                if(res) resetForm(false)
            })
            .catch(error => console.log(error))
    }

    const resetForm = (reset = true) => {
        if(reset)
            setForm(FORM_IS)
        getAll()
    }

    const onEditClicked = row => {
        setForm(row)
        focusFirstInput()
    }

    const focusFirstInput = () => {
        document.getElementById("input-marche").select()
    }

    return (
        <div>
            <AdminPageTitle title={"Situations"}/>
            <div>
                <form onSubmit={submit} className={"row"}>
                    <div className="col-12 col-md-4">
                        <div className="form-group">
                            <label htmlFor="input-marche">Label</label>
                            <input required value={form.label} onChange={e => setForm({...form, label: e.target.value})} type="text" className={"form-control"} id={"input-marche"}/>
                        </div>
                    </div>
                    <div className="col-12 col-md-3  d-flex">
                        <div className="form-group d-flex align-items-end">
                            {
                                form.id ?
                                    <div className={"d-flex"}>
                                        <button type={"submit"} className={"btn btn-warning"}><BiSave/> Enregistrer</button>
                                        <button onClick={resetForm} className={"btn btn-error"}><CgClose/></button>
                                    </div>
                                    :
                                    <button type={"submit"} className={"btn btn-success"}><BiPlus/> Ajouter un marché</button>
                            }
                        </div>
                    </div>

                </form>
                <table className={"mt-2 table table-bordered table-hover table-sm"}>
                    <thead className={"table-dark"}>
                    <tr>
                        <th width={"5%"}>#</th>
                        <th width={"70%"}>Label</th>
                        <th width={"15%"}>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {

                        rows.map((row,i) => (
                            <tr key={row.id}>
                                <td>{i+1}</td>
                                <td>{row.label}</td>
                                <td className={"d-flex"}><button style={{marginRight: 8}} className={"btn btn-danger btn-sm"} onClick={() => deleteEntity(row.id)}>supp.</button><button className={"btn btn-warning btn-sm"} onClick={() => onEditClicked(row)}>modif.</button></td>
                            </tr>
                        ))
                    }
                    </tbody>
                </table>

            </div>
        </div>
    )
}
