import React, { useState } from "react";



function Gallery({images = [], imageHeight = 300}) {
  const [selectedImage, setSelectedImage] = useState(null);

  const openImage = (image) => {
    setSelectedImage(image);
  };

  const closeImage = () => {
    setSelectedImage(null);
  };

  return (
    <div className="gallery-container">
      <div className="row gx-3 gy-2">
        {images.map((image, index) => (
          <div key={index} className="col-md-4">
            <div className="image-item" onClick={() => openImage(image)}>
              <img height={imageHeight} width={"100%"} src={image} alt={`${index}`} />
            </div>
          </div>
        ))}
      </div>

      {selectedImage && (
        <div className={`image-preview ${selectedImage ? "active" : ""}`}>
          <span className="close-button" onClick={closeImage}>
            &times;
          </span>
          <img src={selectedImage} alt="Preview" className="img-fluid" />
        </div>
      )}
    </div>
  );
}

export default Gallery;
