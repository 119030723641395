import { NavLink, Outlet } from "react-router-dom";
import { BiImage, BiListCheck } from "react-icons/bi";

export default function GestionSpa() {
  return (
    <div className="container">
      <h2 className="my-3 border-bottom">Gestion Spas</h2>

      <div>
        <NavLink to={"media"}>
          <BiImage />
          Vidéos et images
        </NavLink>
        <span> / </span>
        <NavLink to={"liste-prix"}>
          <BiListCheck />
          Liste des prix
        </NavLink>
      </div>

      <div className="my-3">
        <Outlet />
      </div>
    </div>
  );
}
