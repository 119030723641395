import AdminPageTitle from "../../components/admin-page-title";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {reservationsActions, reservationsStateSelector} from "../../app/slice/reservations-slice";
import moment from "moment";
import ReservationService from "../../service/reservation.service";
const service = new ReservationService()
export default function Reservations(){
    const {rows,filterForm} = useSelector(reservationsStateSelector)
    const dispatch = useDispatch()
    const search = e => {
        e.preventDefault()
        getAll()
    }

    const getAll = () => {
        service
            .getAll(filterForm)
            .then(rows => dispatch(reservationsActions.setRows(rows)))
            .catch(error => console.log(error))
    }

    useEffect(() => {
        getAll()
    }, [])

    const gererAnnulationReservation = id => {
        service
        .gererAnnulationReservation(id)
        .then(res => getAll())
        .catch(error => alert(JSON.stringify(error)))
    }
    return (
        <div>
            <AdminPageTitle title={"Réservations"}/>
            <form className={"mb-2"} onSubmit={search}>
                <div className="row">
                    <div className="col-12 col-md-2">
                        <div className="form-group">
                            <label htmlFor="">Date debut réservation</label>
                            <input value={filterForm.date_debut_reservation} onChange={e => dispatch(reservationsActions.setFilterForm({field: "date_debut_reservation", value: e.target.value}))} type="date" className={"form-control"}/>
                        </div>
                    </div>
                    <div className="col-12 col-md-2">
                        <div className="form-group">
                            <label htmlFor="">Date fin réservation</label>
                            <input value={filterForm.date_fin_reservation} onChange={e => dispatch(reservationsActions.setFilterForm({field: "date_fin_reservation", value: e.target.value}))} type="date" className={"form-control"}/>
                        </div>
                    </div>
                    <div className="col-12 col-md-2">
                        <div className="form-group">
                            <label htmlFor="">Date début séjour</label>
                            <input value={filterForm.date_debut_sejour} onChange={e => dispatch(reservationsActions.setFilterForm({field: "date_debut_sejour", value: e.target.value}))} type="date" className={"form-control"}/>
                        </div>
                    </div>
                    <div className="col-12 col-md-2">
                        <div className="form-group">
                            <label htmlFor="">Date fin séjour</label>
                            <input value={filterForm.date_fin_sejour} onChange={e => dispatch(reservationsActions.setFilterForm({field: "date_fin_sejour", value: e.target.value}))} type="date" className={"form-control"}/>
                        </div>
                    </div>
                    <div className="col-12 col-md-1">
                        <div className="form-group">
                            <label htmlFor="">{`Nb_nuits >=`}</label>
                            <input value={filterForm.nb_nuits_sup} onChange={e => dispatch(reservationsActions.setFilterForm({field: "nb_nuits_sup", value: Number(e.target.value)}))} type="number" className={"form-control"}/>
                        </div>
                    </div>
                    <div className="col-12 col-md-1">
                        <div className="form-group">
                            <label htmlFor="">{`Nb_nuits  <=`} </label>
                            <input value={filterForm.nb_nuits_inf} onChange={e => dispatch(reservationsActions.setFilterForm({field: "nb_nuits_inf", value: Number(e.target.value)}))} type="number" className={"form-control"}/>
                        </div>
                    </div>
                    <div className="col-12 col-md-2 d-flex align-items-end">
                        <div className="form-group">
                            <button type={"submit"} className={"btn btn-primary"}><i className="bi bi-search"/>Rechercher</button>
                        </div>
                    </div>

                </div>
            </form>
           <div className="table-responsive">
           <table className={"table table-striped table-bordered table-hover"}>
                <thead className={"table-dark"}>
                <tr>
                    <th>#</th>
                    <th>Client</th>
                    <th>E-mail</th>
                    <th>Tel</th>
                    <th>adresse</th>
                    <th>Nb_chambres</th>
                    <th>ad</th>
                    <th>enf</th>
                    <th>bébés</th>
                    <th>date_réservation</th>
                    <th>date_début_séjour</th>
                    <th>date_fin_séjour</th>
                    <th>nb_nuits</th>
                    <th>Détails</th>
                    <th>Total</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {
                    rows.map(r => (
                        <tr style={{background: r.etat === "annulé" ? "#ffe2e2" : "#eaffea"}} key={r.id}>
                            <td>{r.id}</td>
                            <td>{r.nom}</td>
                            <td>{r.email}</td>
                            <td>{r.tel}</td>
                            <td>{r.adresse}</td>
                            <td>{r.nb_chambres}</td>
                            <td>{r.nb_adultes}</td>
                            <td>{r.nb_enfants}</td>
                            <td>{r.nb_bebes}</td>
                            <td>{moment(r.date_reservation).format("DD/MM/YYYY")}</td>
                            <td>{moment(r.date_debut_sejour).format("DD/MM/YYYY")}</td>
                            <td>{moment(r.date_fin_sejour).format("DD/MM/YYYY")}</td>
                            <td>{r.nb_nuits}</td>
                            <td>
                                <ul>
                                    {
                                        r.hebergement_reservations.map(h => (
                                            <li key={h.id}>{h.hebergement}
                                                <ul>
                                                    <li>ads: {h.nb_adultes}</li>
                                                    <li>enfs: {h.nb_enfants}</li>
                                                    <li>bbs: {h.nb_bebes}</li>
                                                    <li>arr: {h.arrangement}</li>
                                                    <li>prix: {h.prix.toFixed(3)}</li>
                                                </ul>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </td>
                            <td>{r.total.toFixed(3)}</td>
                            <td><button onClick={() => gererAnnulationReservation(r.id)}>{r.etat === "active" ? "Annuler" : "activer"}</button></td>
                        </tr>
                    ))
                }
                <tr>
                    <td colSpan={14} align={"right"}>Total</td>
                    <td align={"right"}>{rows.reduce((p,c) => p + Number(c.total),0).toFixed(3)}</td>
                </tr>
                </tbody>
            </table>
           </div>
        </div>
    )
}
